import { api, createApiCall } from "../api";
import { setProfile } from "../features/profile";
import { ProfileRequest, ProfileResponse } from "../../interfaces/profile";

const profileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<ProfileResponse, void>({
      query: () => ({
        url: "/profile/me",
        method: "GET"
      }),
    }),
    createProfile: builder.query<void, ProfileRequest>({
      query: (body) => ({
        url: `/account-details/register`,
        method: "PUT",
        body
      })
    }),
    updateProfile: builder.query<void, ProfileRequest>({
      query: (body) => ({
        url: `/account-details`,
        method: "PUT",
        body
      })
    }),
    uploadAvatar: builder.query<void, { profilePhoto: string }>({
      query: (body) => ({
        url: `/user-details/profile-photo`,
        method: "POST",
        body
      })
    }),
    uploadCompanyLogo: builder.query<void, { logo: string }>({
      query: (body) => ({
        url: `/account-details/logo`,
        method: "POST",
        body
      })
    }),
    deleteProfile: builder.query<void, string>({
      query: (email) => ({
        url: `/account_block/accounts/:id?email=${email}`,
        method: "DELETE"
      })
    }),
    subscribeForUpdates: builder.query<void, { subscribe: boolean }>({
      query: (body) => ({
        url: `/academy/subscription-list`,
        method: "PUT",
        body
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getProfile = createApiCall<ProfileResponse, void>(profileAPI.endpoints.getProfile, { slicer: 'profile', key: 'metadata', setFunction: setProfile });
export const createProfile = createApiCall<void, ProfileRequest>(profileAPI.endpoints.createProfile);
export const updateProfile = createApiCall<void, ProfileRequest>(profileAPI.endpoints.updateProfile);
export const uploadAvatar = createApiCall<void, FormData>(profileAPI.endpoints.uploadAvatar);
export const uploadCompanyLogo = createApiCall<void, FormData>(profileAPI.endpoints.uploadCompanyLogo);
export const deleteProfile = createApiCall<void, string>(profileAPI.endpoints.deleteProfile);
export const subscribeForUpdates = createApiCall<void, { subscribe: boolean }>(profileAPI.endpoints.subscribeForUpdates);
