import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Grid, Paper } from "@material-ui/core";

import { useStyles } from './styles';

const SalesAssetsRef = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Typography className={classes.text}>COMING SOON!</Typography>
    </Box>
  );
};

export default SalesAssetsRef;
