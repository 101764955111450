import React from "react";
import Layout from "./Layout.web";
import AboutTheCompany from "../../email-account-registration/main/AboutTheCompany.web";
import AboutYourApproach from "../../email-account-registration/main/AboutYourApproach.web";
import OfferTheCompany from "../../email-account-registration/main/OfferTheCompany.web";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import MessageEnum, { getName } from "../../../framework/main/Messages/MessageEnum";
import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import StorageProvider from "../../../framework/main/StorageProvider";
import { aboutCompanyOnboradingData, yourApproachOnboradingData, offeringsOnboradingData } from "../../../helpers/onboarding";
import { Backdrop, CircularProgress } from "@material-ui/core";
import SkipOnboardingConfirmationDialog from "../../../components/main/SkipOnboardingConfirmationDialog";
import { getOnboardingCompleteness, getOnboardingData } from "../../../redux/services/onboarding";
import { getCurrentSubscription } from "../../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../../helpers/subscription";
import ConfirmationModal from "../../../components/main/ConfirmationModal";
import { notify } from "../../../providers/notification";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  token?: string;
}

interface S {
  loading: boolean;
  steps: any[];
  activeStep: any;
  activePartOfStep: any;
  completedSteps: any;
  autoSavedValues: any;
  isSubscriptionActive: boolean,
  formRef: null,
  tooltips: any,
}

interface SS {
  id: any;
}

export default class OnboardingLayout extends BlockComponent<Props, S, SS> {
  token: string = "";
  isEdit: boolean = false;

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),

    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,
      steps: [
        {
          id: "company",
          label: "Company",
          redirectUrl: '/onboarding/about-the-company',
          step: 1
        },
        {
          id: "approach",
          label: "Approach",
          redirectUrl: '/onboarding/about-your-approach',
          step: 2
        },
        {
          id: "offerings",
          label: "Offerings",
          redirectUrl: '/onboarding/offering-the-company',
          step: 3
        },
      ],
      completedSteps: {
        company: {
          must: {
            data: {},
            completed: false
          },
          should: {
            data: {},
            completed: false
          },
          good: {
            data: {},
            completed: false
          },
        },
        approach: {
          must: {
            data: {},
            completed: false
          },
          should: {
            data: {},
            completed: false
          },
          good: {
            data: {},
            completed: false
          },
        },
        offerings: {
          must: {
            data: {},
            completed: false
          },
          should: {
            data: {},
            completed: false
          },
          good: {
            data: {},
            completed: false
          },
        },
      },
      activeStep: 1,
      activePartOfStep: 1,
      autoSavedValues: {},
      isSubscriptionActive: false,
      formRef: null,
      tooltips: {
        company: {
          autoFill: true,
          mustHave: true,
          save: true
        },
        approach: {
          autoFill: true,
          mustHave: true,
          save: true
        },
        offerings: {
          autoFill: true,
          mustHave: true,
          save: true
        }
      }
    };
  }

  async componentDidMount() {
    const isEdit = await StorageProvider.getItem("isEdit");
    this.isEdit = !!isEdit;

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    const currentStep = this.props.navigation.getParam('step');
    const isValidStep = !!this.state.steps.find(item => item.redirectUrl.includes(currentStep))

    if (!currentStep || !isValidStep) {
      this.props.navigation.navigate('/onboarding/about-the-company');
      return;
    }

    await this.handleIsSubscriptionActive();
    await this.fetchInitData(true);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS): void {
    if (
      (prevProps.history.location.search !== this.props.history.location.search) ||
      (prevProps.history.location.pathname !== this.props.history.location.pathname) ||
      (prevState.activeStep !== this.state.activeStep) ||
      (prevState.activePartOfStep !== this.state.activePartOfStep)
    ) {
      setTimeout(() => {
        this.fetchInitData(false);
      }, 1500);
    }
  }

  fetchInitData = async (withLoader) => {
    this.setState({
      loading: withLoader
    })

    const { data, error } = await getOnboardingData();

    if (data && Array.isArray(data) && !error) {
      this.handleSetCompletedSteps(data);
    } else if (error.status === 402) {
      notify.info('To proceed, you have to be subscribed');

      this.props.navigation.navigate('/subscriptions');
    } else if (error.status === 422) {
      notify.info('To proceed, you have to be logged in');

      this.props.navigation.navigate('/');
    } else {
      this.props.navigation.navigate('/dashboard');
    }

    this.setState({
      loading: false
    })
  }

  handleSetCompletedSteps = (data) => {
    const company = aboutCompanyOnboradingData(data);
    const approach = yourApproachOnboradingData(data);
    const offerings = offeringsOnboradingData(data);

    this.setState({
      completedSteps: {
        company: {
          must: {
            data: company.mustHaveSection,
            completed: company.isMustHaveCompleted
          },
          should: {
            data: company.shouldHaveSection,
            completed: company.isShouldHaveCompleted
          },
          good: {
            data: company.goodHaveSection,
            completed: company.isGoodHaveCompleted
          },
        },
        approach: {
          must: {
            data: approach.mustHaveSection,
            completed: approach.isMustHaveCompleted
          },
          should: {
            data: approach.shouldHaveSection,
            completed: approach.isShouldHaveCompleted,
          },
          good: {
            data: approach.goodHaveSection,
            completed: approach.isGoodHaveCompleted,
          },
        },
        offerings: {
          must: {
            data: offerings.mustHaveSection,
            completed: offerings.isMustHaveCompleted,
          },
          should: {
            data: offerings.shouldHaveSection,
            completed: offerings.isShouldHaveCompleted
          },
          good: {
            data: offerings.goodHaveSection,
            completed: offerings.isGoodHaveCompleted
          },
        },
      }
    });
  }

  handleIsSubscriptionActive = async () => {
    this.setState({
      loading: true
    })

    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) === 'EXPIRED' || getCurrentSubscriptionStatus(data) === 'ABSENT') {
      notify.info('To proceed, you have to be subscribed');

      this.props.navigation.navigate('/subscriptions')
    } else {
      this.setState({
        loading: false,
        isSubscriptionActive: true
      })
    }
  }

  backButton = async () => {
    const isEdit = await StorageProvider.getItem('isEdit');

    if (!!isEdit) {
      this.props.history.push('/dashboard');
    } else {
      this.props.history.push('/upload-company-info');
    }
  }

  redirectToStep = (url) => {
    if (url !== window.location.pathname) {
      this.props.navigation.navigate(url);
    }
  }

  setActiveStep = (stepIndex) => {
    this.setState({
      activeStep: stepIndex + 1,
      activePartOfStep: 1,
    });
  }

  setActivePartOfStep = (stepIndex) => {
    this.setState({
      activePartOfStep: stepIndex + 1,
    });
  }

  setAutoSaveData = async (page: string, values: any) => {
    if (values) {
      // const newValues = {
      //   ...this.state.autoSavedValues,
      //   [page]: values
      // };

      this.setState({
        autoSavedValues: {
          page: values
        }
      })

      const { data, error } = await getOnboardingData();

      if (data && Array.isArray(data) && !error) {
        this.handleSetCompletedSteps(data);
      }
    } else {
      const newValues = {
        ...this.state.autoSavedValues,
      }

      delete newValues[page];

      this.setState({
        autoSavedValues: newValues
      })

      const { data, error } = await getOnboardingData();

      if (data && Array.isArray(data) && !error) {
        this.handleSetCompletedSteps(data);
      }
    }
  }

  handleSetFormRef = (formRef) => {
    this.setState({
      formRef
    })
  }

  handleCloseTooltip = (section, key) => {
    this.setState({
      tooltips: {
        ...this.state.tooltips,
        [section]: {
          ...this.state.tooltips[section],
          [key]: false
        }
      }
    })
  }

  renderStepComponent = () => {
    const currentStep = this.props.navigation.getParam('step');

    if (currentStep) {
      switch (this.props.navigation.getParam('step')) {
        case 'about-the-company':
          return (
            <AboutTheCompany
              {...this.props}
              history={this.props.history}
              setAutoSaveData={this.setAutoSaveData}
              autoSavedValues={this.state.autoSavedValues}
              setFormRef={this.handleSetFormRef}
              closeTooltip={this.handleCloseTooltip}
              tooltips={this.state.tooltips}
            />
          );
        case 'about-your-approach':
          return (
            <AboutYourApproach
              {...this.props}
              history={this.props.history}
              setAutoSaveData={this.setAutoSaveData}
              autoSavedValues={this.state.autoSavedValues}
              setFormRef={this.handleSetFormRef}
              closeTooltip={this.handleCloseTooltip}
              tooltips={this.state.tooltips}
            />
          );
        case 'offering-the-company':
          return (
            <OfferTheCompany
              {...this.props}
              history={this.props.history}
              setAutoSaveData={this.setAutoSaveData}
              autoSavedValues={this.state.autoSavedValues}
              setFormRef={this.handleSetFormRef}
              closeTooltip={this.handleCloseTooltip}
              tooltips={this.state.tooltips}
            />
          );
        default:
          return (
            <Backdrop
              style={{ color: '#fff', zIndex: 999999 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )
      }
    }
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <Backdrop
            style={{ color: '#fff', zIndex: 9999 }}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Layout
          back={this.backButton}
          stickyHeader
          isEdit={this.isEdit}
          carousel={"hide"}
          poweredByLogo={false}
          onboardingProps={{
            onRedirect: this.redirectToStep,
            setActiveStep: this.setActiveStep,
            setActivePartOfStep: this.setActivePartOfStep,
            steps: this.state.steps,
            completedSteps: this.state.completedSteps,
            activeStep: this.state.activeStep,
            activePartOfStep: this.state.activePartOfStep,
            param: this.props.navigation.getParam('step'),
            formRef: this.state.formRef
          }}
        >
          {this.state.isSubscriptionActive
            ? this.renderStepComponent()
            : <Backdrop
              style={{ color: '#fff', zIndex: 9999 }}
              open
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        </Layout>
      </>
    );
  }
}
