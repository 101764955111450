import React, { useEffect, useState } from 'react';
import { Avatar, Backdrop, Box, Button, CircularProgress, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { ReactComponent as AddUserIcon } from '../../assets/icons/plus_user.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import InviteUserModal from './components/InviteUserModal';
import { PERMISSIONS } from '../../utils/enums';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { activateAccountUser, deactivateAccountUser, deleteAccountUser, getAccountUsers, inviteAccountUser, updateAccountUser } from '../../redux/services/user-management';
import Dropdown from '../../components/main/CustomFields/Dropdown';
import { getProfile } from '../../redux/services/profile';
import { notify } from '../../providers/notification';
import { ProfileResponse } from '../../interfaces/profile';

import { UserManagementResponse } from "../../interfaces/user-management";

interface Props {
  navigation: any;
}

const UserManagement: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserManagementResponse[] | null>();
  const [userToDelete, setUserToDelete] = useState<UserManagementResponse | null>(null);
  const [showInviteUserModal, setShowInviteUserModal] = useState<boolean>(false);
  const [profile, setProfile] = useState<ProfileResponse | null>(null);

  useEffect(() => {
    handleGetProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      handleGetUsers();
    }
  }, [profile]);

  const handleGetUsers = async () => {
    setLoading(true);

    const { data, error } = await getAccountUsers();

    if (data && !error) {
      let newUsers = data.filter(item => item.email !== profile?.account?.email);
      const originalUser = data.find(item => item.email === profile?.account?.email)
      newUsers.unshift(originalUser);

      setUsers(newUsers);
    }

    setLoading(false);
  }

  const handleGetProfile = async () => {
    const { data, error } = await getProfile(null, true);

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleSetPermission = async (id: string, permission: string) => {
    setLoading(true);

    const { error } = await updateAccountUser({
      userId: id,
      body: {
        role: permission.toUpperCase().replace(' ', '_')
      }
    });

    if (!error) {
      notify.success('Permission has been changed successfully');
    } else {
      notify.error('Failed to change user permission');
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleDeleteUser = async () => {
    setLoading(true);
    setUserToDelete(null);

    const { error } = await deleteAccountUser(userToDelete.uuid);

    if (!error) {
      notify.success(`User has been deleted successfully`);
    } else {
      notify.error(`Failed to delete user`);
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleDeactivateUser = async (id: string) => {
    setLoading(true);

    const { error } = await deactivateAccountUser(id);

    if (!error) {
      notify.success(`User has been deactivated successfully`);
    } else {
      notify.error(error?.data?.message || `Failed to deactivate user`);
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleActivateUser = async (id: string) => {
    setLoading(true);

    const { error } = await activateAccountUser(id);

    if (!error) {
      notify.success(`User has been activated successfully`);
    } else {
      notify.error(`Failed to activate user`);
    }

    setLoading(false);
    handleGetUsers();
  }

  const handleInviteUsers = async (values) => {
    setShowInviteUserModal(false);
    setLoading(true);

    const { error } = await inviteAccountUser({
      email: values.email,
      fullName: values.name,
      role: values.permission.toUpperCase(),
    });

    if (!error) {
      notify.success(`Invitation has been sent successfully`);
    } else if (error.data.code === 409) {
      notify.error(`This user already exists in the system`);
    } else {
      notify.error(`Failed to send invitation`);
    }

    setLoading(false);
    handleGetUsers();
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 99999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container
        style={{
          minWidth: '1017px',
          maxWidth: '100%',
          width: '100%',
          padding: '40px 30px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '20px',
        }}
      >
        <Typography
          style={{
            fontSize: '48px',
            fontWeight: '600',
            color: '#000000',
            lineHeight: '58px'
          }}
        >
          User Management
        </Typography>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '20px'
          }}
        >
          <Button
            variant="contained"
            startIcon={<AddUserIcon />}
            onClick={() => setShowInviteUserModal(true)}
            style={{
              minWidth: '130px',
              minHeight: '44px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '19px',
              color: '#FFFFFF',
              padding: '12px 14px',
              borderRadius: '8px',
              textTransform: 'none',
              boxShadow: 'none',
              background: '#000000',
            }}
          >
            Add user
          </Button>
        </Box>
        <TableContainer
          component={Paper}
          style={{
            width: '100%',
            minWidth: '860px',
            boxShadow: 'none',
            margin: '0px auto'
          }}
        >
          <Table>
            <TableBody>
              {users?.map((row, index) => (
                <TableRow key={row.uuid}>
                  <TableCell
                    align="center"
                    style={{
                      width: '5%'
                    }}
                  >
                    <Avatar
                      src={row.profilePhoto}
                      alt={row.name}
                      style={{ width: '40px', height: '40px', margin: '0 auto' }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: '35%'
                    }}
                  >
                    <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', marginBottom: '2px' }}>
                      {row.name}
                    </Typography>
                    <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                      {row.email}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      width: '15%'
                    }}
                  >
                    <Dropdown
                      required
                      disabled={row.email === profile?.account?.email || row.email === profile?.user?.email}
                      value={PERMISSIONS[row.role]}
                      options={Object.values(PERMISSIONS).map(item => ({ value: item, label: item }))}
                      onChange={(event, value) => handleSetPermission(row.uuid, event.target.value)}
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      width: '35%'
                    }}
                  >
                    <Button
                      color='secondary'
                      variant='outlined'
                      disabled={!row.isRegistered || row.email === profile?.account?.email || row.email === profile?.user?.email}
                      onClick={() => {
                        row.isActive
                          ? handleDeactivateUser(row.uuid)
                          : handleActivateUser(row.uuid)
                      }}
                    >
                      {row.isActive
                        ? 'Deactivate'
                        : 'Activate'
                      }
                    </Button>
                    &nbsp;
                    &nbsp;
                    <IconButton
                      disabled={row.isRegistered || row.email === profile?.account?.email || row.email === profile?.user?.email}
                      onClick={() => setUserToDelete(row)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!users?.length && (
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '80px 0px' }}>
              <Typography>
                No users
              </Typography>
            </Box>
          )}
        </TableContainer>
      </Container>

      <ConfirmationModal
        open={!!userToDelete}
        title={'Are you sure you want to delete user from the plarform?'}
        description={'Note that all data he or she saved and generated will be deleted'}
        rejectBtnText={'Close'}
        confirmBtnText={'Delete user'}
        onClose={() => setUserToDelete(null)}
        onReject={() => setUserToDelete(null)}
        onConfirm={() => handleDeleteUser()}
      />

      <InviteUserModal
        open={showInviteUserModal}
        onClose={() => setShowInviteUserModal(false)}
        onInvite={handleInviteUsers}
      />
    </>
  );
};

export default UserManagement;
