import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet-async";
import { Avatar, Badge, Box, Button, CircularProgress, IconButton, TextField, Typography } from "@material-ui/core";
import { ReactComponent as CopilotIcon } from '../../assets/icons/copilot_gold.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as CommunicationIcon } from '../../assets/icons/communication_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SendMessageIcon } from '../../assets/icons/send_message_icon.svg';
import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/side_bar_open_icon.svg';
import { ReactComponent as WelcomeToGeneralChat } from '../../assets/icons/welcome_to_general_chat.svg';
import { ReactComponent as WelcomeToIntelligenceChat } from '../../assets/icons/welcome_to_intelligence_chat.svg';
import { ReactComponent as RegenerateWhite } from '../../assets/icons/regenerate_white.svg';
import CopilotRobot from '../../assets/images/copilot_robot.webp';
import ThinkingCopilotRobot from '../../assets/images/thinking_copilot_robot.webp';
import { getProfile } from "../../redux/services/profile";
import { deleteChat, getChatById, getChats, sendChatQuestion, sendChatQuestionById } from "../../redux/services/copilot";
import TypingText from "./components/TypingText";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import LimitationWidget from "../../components/main/LimitationWidget";
import { getOnboardingCompleteness } from "../../redux/services/onboarding";
import SideBar from "./components/SideBar";
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { observableService } from "../../services/observable";
import { notify } from "../../providers/notification";
import { AnswerStatus, Chat, CopilotTypes, MessageAuthorTypes } from "../../interfaces/copilot";
import { ProfileResponse } from "../../interfaces/profile";

import { useStyles } from "./styles";

const Copilot = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [chats, setChats] = useState<Chat[]>(null);
  const [chat, setChat] = useState<Chat>(null);
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const [message, setMessage] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [allowAnimate, setAllowAnimate] = useState<boolean>(false);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(false);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState<boolean>(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const classes = useStyles();
  const messagesRef = useRef<any>(null);

  useEffect(() => {
    const { params } = props.match;

    if (params.tab === CopilotTypes.GENERAL || params.tab === CopilotTypes.INTELLIGENCE) {
      setChat(null);
      setMessage('');
      setAllowAnimate(false);

      handleGetChats();

      if (params.id) {
        handleGetChatById(params.id);
      }
    } else {
      props.history.push(`/copilot/${CopilotTypes.GENERAL}`);
    }

    return () => {
      setAllowAnimate(false);
    };
  }, [props.match.params]);

  useEffect(() => {
    // if (window.location.pathname.includes('/chat')) {
    //   setSideBarOpen(false);
    // }

    Promise.allSettled([
      handleIsSubscriptionActive(),
      handleIsOnboardingCompleted(),
    ]).then(() => {
      handleGetProfile();
    })
  }, []);

  // Clean up the controller when tab or chat changed
  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [props.match.params, chat]);

  useEffect(() => {
    if (!fetching && chat?.messages && messagesRef?.current) {
      scrollToBottom();
    }
  }, [fetching, chat?.messages, messagesRef]);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  }

  const handleIsOnboardingCompleted = async () => {
    const { data } = await getOnboardingCompleteness();

    if (data && Object.values(data).every(item => item)) {
      setIsOnboardingCompleted(true);
    }
  }

  const handleGetProfile = async () => {
    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleGetChats = async (addNewChat = true) => {
    setLoading(!chats?.length);

    const { data, error } = await getChats(getCurrentChatType());

    if (data && !error) {
      if (addNewChat && !getCurrentChatId()) {
        setChats([
          {
            id: 'none',
            title: 'New chat'
          },
          ...data
        ]);
      } else {
        setChats(data);
      }
    }

    setLoading(false);
  }

  const handleGetChatById = async (id) => {
    setAllowAnimate(false);
    setFetching(true);

    const { data, error } = await getChatById({ id, tab: getCurrentChatType() });

    if (data && !error) {
      setChat({
        ...data,
        messages: data.messages.map(item => [
          {
            id: item.id,
            author: MessageAuthorTypes.USER,
            answerStatus: item.answerStatus,
            message: item.question,
          },
          {
            id: item.id,
            author: MessageAuthorTypes.COPILOT,
            answerStatus: item.answerStatus,
            message: item.answer || 'error',
          },
        ]).flat(),
      })
    } else {
      notify.error('Chat not found');

      props.history.push(`/copilot/${getCurrentChatType()}`);
    }

    setFetching(false);

    setTimeout(() => {
      setAllowAnimate(true);
    }, 100);
  };

  const handleSendChatQuestion = async (incomingMessage = undefined) => {
    if (!chat || chat.messages.every(item => item?.message?.trim()?.length)) {
      if (isOnboardingCompleted && isSubscriptionActive) {
        // Create a new AbortController for the current request
        const controller = new AbortController();
        setAbortController(controller);

        setAllowAnimate(true);

        const question = incomingMessage || message;
        const userMessageId = uuidv4();
        const copilotMessageId = uuidv4();

        setMessage('');

        const newMessages = [
          ...(chat?.messages || []),
          {
            id: userMessageId,
            author: MessageAuthorTypes.USER,
            answerStatus: AnswerStatus.UNEVALUATED,
            message: question,
          },
          {
            id: copilotMessageId,
            author: MessageAuthorTypes.COPILOT,
            answerStatus: AnswerStatus.UNEVALUATED,
            message: '',
          },
        ]

        if (chat?.sessionId) {
          setChat({
            ...chat,
            messages: newMessages
          })

          const { data, error } = await sendChatQuestionById({ 
            body: { question, chatType: getCurrentChatType().toUpperCase() }, 
            sessionId: chat.sessionId,
            tab: getCurrentChatType(),
            signal: controller.signal
          });

          if (error?.status === 402) {
            observableService.sendEvent(`Show top up modal for ${getCurrentChatType() === CopilotTypes.GENERAL ? 'COPILOT' : 'COPILOT_INTELLIGENCE'}`);
          } else if (!error?.error?.includes('AbortError')) {
            if (data) {
              observableService.sendEvent('Increase usage amount');
            }

            setChat({
              ...chat,
              messages: newMessages.map(item => (
                item.id === copilotMessageId
                  ? {
                    ...item,
                    id: data ? data.messageId : copilotMessageId,
                    message: data ? data.answer : 'error'
                  }
                  : item
              ))
            })
          }
        } else {
          setChat({
            ...chat,
            messages: newMessages
          })

          const { data, error } = await sendChatQuestion({
            body: { question, chatType: getCurrentChatType().toUpperCase() },
            tab: getCurrentChatType(),
            signal: controller.signal
          });

          if (error?.status === 402) {
            observableService.sendEvent(`Show top up modal for ${getCurrentChatType() === CopilotTypes.GENERAL ? 'COPILOT' : 'COPILOT_INTELLIGENCE'}`);
          } else if (!error?.error?.includes('AbortError')) {
            if (data) {
              observableService.sendEvent('Increase usage amount');
            }

            setChat({
              ...data,
              messages: newMessages.map(item => (
                item.id === copilotMessageId
                  ? {
                      ...item,
                      id: data ? data.messages[0].id : copilotMessageId,
                      message: data ? data.messages[0].answer : 'error'
                    }
                  : item
              ))
            })
          }
        }

        handleGetChats(false);
      } else {
        setTimeout(() => {
          setShowActionBlockedModal(true);
        }, 100);
      }
    }
  }

  const scrollToBottom = () => {
    if (messagesRef?.current) {
      setTimeout(() => {
        messagesRef.current.scrollTo({
          top: messagesRef.current.scrollHeight - 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const handleKeyDown = (event) => {
    if (!chat || chat.messages.every(item => item?.message?.trim()?.length)) {
      const keyPressed = event.key || event.code || event.nativeEvent.code || event.nativeEvent.key;

      if (keyPressed?.toLowerCase() === 'enter' && !event.shiftKey && !!message?.trim()?.length) {
        event.preventDefault(); // Prevents newline on Enter

        handleSendChatQuestion();
      }
    }
  };

  const getCurrentChatType = () => {
    const { params } = props.match;

    return params.tab
  }

  const getCurrentChatId = () => {
    const { params } = props.match;

    return params.id
  }

  return (
    <>
      {/* <Helmet>
        <title>
          {getCurrentChatType() === 'intelligence'
            ? 'Intelligence'
            : 'General'
          } Copilot | Pebbles Ai
        </title>
      </Helmet> */}

      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.headerLeftBox}>
              <CopilotIcon style={{ minWidth: '16px', minHeight: '18px' }} />
              <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                {chat?.title || 'New chat'}
              </Typography>
            </Box>
            <Box className={classes.headerCenterBox}>
              <Box className={classes.tabs}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.comingSoon}
                  // disabled={getCurrentChatType() !== CopilotTypes.GENERAL}
                  onClick={() => {
                    if (getCurrentChatType() !== CopilotTypes.GENERAL) {
                      props.history.push(`/copilot/${CopilotTypes.GENERAL}`);
                    }
                  }}
                  style={{
                    background: getCurrentChatType() === CopilotTypes.GENERAL ? 'black' : 'transparent',
                    color: getCurrentChatType() === CopilotTypes.GENERAL ? 'white' : 'black'
                  }}
                >
                  General chat
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.comingSoon}
                  // disabled={getCurrentChatType() !== 'intelligence'}
                  onClick={() => {
                    if (getCurrentChatType() !== 'intelligence') {
                      props.history.push(`/copilot/intelligence`);
                    }
                  }}
                  style={{
                    background: getCurrentChatType() === 'intelligence' ? 'black' : 'transparent',
                    color: getCurrentChatType() === 'intelligence' ? 'white' : 'black',
                  }}
                >
                  Intelligence chat
                  {/* <Typography style={{ fontSize: '10px', fontWeight: '400', lineHeight: '12.4px', color: '#475569' }}>
                    (coming soon)
                  </Typography> */}
                </Button>
              </Box>
            </Box>
            <Box className={classes.headerRightBox}>
              <Box className={classes.actions}>
                <LimitationWidget
                  type={getCurrentChatType() === CopilotTypes.GENERAL ? 'COPILOT' : 'COPILOT_INTELLIGENCE'}
                />
                {/* <IconButton>
                  <DeleteIcon />
                </IconButton> */}
                {!sideBarOpen && (
                  <IconButton
                    onClick={() => setSideBarOpen(true)}
                  >
                    <SideBarOpenIcon />
                  </IconButton>
                )}
              </Box>
              {/* <Button
                fullWidth={false}
                variant="outlined"
                color="secondary"
                startIcon={<CommunicationIcon />}
              >
                Open parameters
              </Button> */}
            </Box>
          </Box>
          <Box className={classes.copilot}>
            <div
              ref={messagesRef}
              className={classes.messages}
              style={{
                padding: (!chat || !chat?.messages?.length) ? '0px' : '28px'
              }}
              id='messages'
            >
              {fetching
                ? <CircularProgress style={{ margin: '0px auto 150px auto', color: '#CAB354' }} />
                : !chat || !chat?.messages?.length
                  ? <Box className={classes.welcomeContainer}>
                      <Box className={classes.welcomeBox}>
                        <Typography className={classes.welcomeText}>
                          WELCOME TO
                        </Typography>
                        <Typography className={classes.chatTitle}>
                          {getCurrentChatType().toUpperCase()} CHAT
                        </Typography>
                      </Box>
                      <Box className={classes.divider} />
                      <Box className={classes.leverageBox}>
                        <Typography className={classes.leverageText}>
                          HERE YOU CAN LEVERAGE:
                        </Typography>
                        <ul className={classes.list}>
                          <li className={classes.listItem}>
                            <Typography className={getCurrentChatType() !== CopilotTypes.GENERAL ? classes.listItemTextSecondary : classes.listItemText}>
                              {getCurrentChatType() === CopilotTypes.GENERAL
                                ? 'Accurate and precise content'
                                : 'Actionable intelligence'
                              }
                              {getCurrentChatType() !== CopilotTypes.GENERAL && (
                                <>
                                  <Box className={classes.soonBox} />
                                  <Typography className={classes.comingSoonText}>
                                    COMING SOON!
                                  </Typography>
                                </>
                              )}
                            </Typography>
                          </li>
                          <li className={classes.listItem}>
                            <Typography className={classes.listItemText}>
                              {getCurrentChatType() === CopilotTypes.GENERAL
                                ? 'Industry-leading reasoning power'
                                : 'Curated GTM knowledge'
                              }
                            </Typography>
                          </li>
                          <li className={classes.listItem}>
                            <Typography className={getCurrentChatType() === CopilotTypes.GENERAL ? classes.listItemTextSecondary : classes.listItemText}>
                              {getCurrentChatType() === CopilotTypes.GENERAL
                                ? 'Upload docs and scrape websites'
                                : 'Up-to-date world data'
                              }
                              {getCurrentChatType() === CopilotTypes.GENERAL && (
                                <>
                                  <Box className={classes.soonBox} />
                                  <Typography className={classes.comingSoonText}>
                                    COMING SOON!
                                  </Typography>
                                </>
                              )}
                            </Typography>
                          </li>
                        </ul>
                      </Box>
                    </Box>
                  : chat.messages.map((item, index) => (
                      <Box key={item.id} className={classes.messagesItem}>
                        <Avatar
                          src={item.author === 'user'
                            ? profile?.user?.profilePhoto
                            : item.message.length
                              ? CopilotRobot
                              : ThinkingCopilotRobot
                          }
                          alt={
                            item.author === 'user'
                              ? profile?.user?.name
                              : 'Copilot'
                          }
                          style={{ width: '40px', height: '40px', border: '1px solid #94A3B8' }}
                        />
                        <TypingText
                          id={item.id}
                          tab={getCurrentChatType()}
                          chat={chat}
                          text={item.message}
                          speed={
                            item.message.length > 3000
                              ? 1
                              : item.message.length > 2000
                                ? 2
                                : 3
                          }
                          author={item.author}
                          answerStatus={item.answerStatus}
                          // allowRefetch={item.message === 'error' && index === chat.messages.length - 1}
                          allowRefetch={false}
                          allowAnimate={allowAnimate}
                          scrollToBottom={scrollToBottom}
                          setChat={setChat}
                        />
                      </Box>
                    ))
              }
            </div>
          </Box>
          {!fetching && chat?.messages && chat.messages[chat.messages.length - 1].message === 'error' && (
            <Box style={{ paddingTop: '20px' }}>
              <Button
                color="primary"
                variant="contained"
                startIcon={<RegenerateWhite />}
                onClick={() => {
                  const lastMessages = chat.messages.slice(-2);

                  handleSendChatQuestion(lastMessages[0].message);
                }}
              >
                Regenerate response
              </Button>
            </Box>
          )}
          <Box className={classes.footer}>
            <Box className={classes.footerInput}>
              <TextField
                fullWidth
                value={message}
                placeholder={'Ask a question to Pebbles Copilot'}
                multiline
                maxRows={8}
                onChange={(event) => setMessage(event.target.value)}
                onKeyDown={handleKeyDown}
              />
              {!!message?.trim()?.length && (
                <IconButton
                  size="small"
                  onClick={() => handleSendChatQuestion()}
                >
                  <SendMessageIcon />
                </IconButton>
              )}
            </Box>
            <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
              Pebbles Copilot is still learning. Verify important information.
            </Typography>
          </Box>
        </Box>

        <SideBar
          open={sideBarOpen}
          tab={getCurrentChatType()}
          chats={chats}
          chat={chat}
          loading={loading}
          setLoading={setLoading}
          isOnboardingCompleted={isOnboardingCompleted}
          isSubscriptionActive={isSubscriptionActive}
          handleGetChats={handleGetChats}
          setSideBarOpen={setSideBarOpen}
          setChat={setChat}
        />
      </Box>

      <ConfirmationModal
        open={showActionBlockedModal}
        title={
          !isSubscriptionActive
            ? 'To proceed, you have to be subscribed'
            : 'To proceed, you have to complete the Onboarding'
        }
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={
          !isSubscriptionActive
            ? 'Subscribe later'
            : 'Complete later'
        }
        rejectBtnText={
          !isSubscriptionActive
            ? 'Go to subscriptions'
            : 'Go to onboarding'
        }
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() =>
          !isSubscriptionActive
            ? props.history.push('/subscriptions')
            : props.history.push('/onboarding')
        }
      />
    </>
  );
};

export default Copilot;
