import React from 'react';
import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

import { useStyles } from './styles';

const CloseButton = ({ closeToast }) => {
  const classes = useStyles();

  return (
    <IconButton 
      className={classes.button}
      onClick={closeToast} 
    >
      <CloseIcon />
    </IconButton>
  );
}

export default CloseButton;
