import { api, apiCall, createApiCall } from "../api";
import { store } from "../store";
import { setAccountSubscription, setSubscriptions } from "../features/subscriptions";
import {
  AccountSubscriptionResponse,
  PaymentHistoryResponse,
  ProductRequest, 
  RevolutOrderResponse,
  SubscriptionTypeResponse,
  TaxInfoRequest,
  TaxInfoResponse
} from "../../interfaces/subscriptions";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query<SubscriptionTypeResponse[], void>({
      query: () => ({
        url: `/v1/license/subscriptions`,
        method: "GET"
      }),
    }),
    getSubscriptionById: builder.query<SubscriptionTypeResponse, string>({
      query: (id) => ({
        url: `/v1/license/subscriptions/${id}`,
        method: "GET"
      }),
    }),
    getPaymentsHistory: builder.query<PaymentHistoryResponse[], void>({
      query: () => ({
        url: `/v1/license/payments/history`,
        method: "GET"
      }),
    }),
    createOrder: builder.query<RevolutOrderResponse, ProductRequest>({
      query: (body) => ({
        url: `/v1/license/orders`,
        method: "POST",
        body
      })
    }),
    applyOrderTax: builder.query<RevolutOrderResponse, string>({
      query: (id) => ({
        url: `/v1/license/orders/${id}`,
        method: "PUT"
      })
    }),
    getTaxInfo: builder.query<TaxInfoResponse, void>({
      query: () => ({
        url: `/v1/license/account-tax`,
        method: "GET"
      })
    }),
    saveTaxInfo: builder.mutation<void, TaxInfoRequest>({
      query: (body) => ({
        url: `/v1/license/account-tax`,
        method: "PUT",
        body
      })
    }),
    getCurrentSubscription: builder.query<AccountSubscriptionResponse, void>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "GET"
      }),
    }),
    cancelSubscription: builder.query<void, void>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "POST"
      })
    }),
    addPaymentMethod: builder.query<RevolutOrderResponse, void>({
      query: () => ({
        url: `/v1/license/payments`,
        method: "POST"
      })
    }),
    renewSubscription: builder.query<void, void>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "PUT"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getSubscriptions = createApiCall<SubscriptionTypeResponse[], void>(subscriptionsAPI.endpoints.getSubscriptions, { slicer: 'subscriptions', key: 'subscriptions', setFunction: setSubscriptions });
export const getSubscriptionById = createApiCall<SubscriptionTypeResponse, string>(subscriptionsAPI.endpoints.getSubscriptionById);
export const getPaymentsHistory = createApiCall<PaymentHistoryResponse[], void>(subscriptionsAPI.endpoints.getPaymentsHistory);
export const createOrder = createApiCall<RevolutOrderResponse, ProductRequest>(subscriptionsAPI.endpoints.createOrder);
export const applyOrderTax = createApiCall<RevolutOrderResponse, string>(subscriptionsAPI.endpoints.applyOrderTax);
export const getTaxInfo = createApiCall<TaxInfoResponse, void>(subscriptionsAPI.endpoints.getTaxInfo);
export const saveTaxInfo = createApiCall<void, TaxInfoRequest>(subscriptionsAPI.endpoints.saveTaxInfo);
export const cancelSubscription = createApiCall<void, void>(subscriptionsAPI.endpoints.cancelSubscription);
export const addPaymentMethod = createApiCall<RevolutOrderResponse, void>(subscriptionsAPI.endpoints.addPaymentMethod);
export const renewSubscription = createApiCall<void, void>(subscriptionsAPI.endpoints.renewSubscription);
export const getCurrentSubscription = createApiCall<AccountSubscriptionResponse, void>(subscriptionsAPI.endpoints.getCurrentSubscription, { slicer: 'subscriptions', key: 'accountSubscription', setFunction: setAccountSubscription });
