import { api, apiCall, createApiCall } from "../api";
import { Campaign, CampaignCommentRequest, CampaignCommentResponse, CommentActionRequest, CreateCampaignRequest, SimplifiedCampaignResponse } from "../../interfaces/campaign";

const myLibraryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getLibraryCampaigns: builder.query<Campaign[], void>({
      query: () => ({
        url: `/v1/library/campaigns`,
        method: "GET"
      }),
    }),
    createLibraryCampaign: builder.mutation<void, { body: { name: string, description: string }, campaignId: string }>({
      query: ({ body, campaignId}) => ({
        url: `/v1/library/campaigns/${campaignId}`,
        method: "POST",
        body
      })
    }),
    duplicateLibraryCampaign: builder.mutation<Campaign, string>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/${campaignId}/duplicate`,
        method: "PUT"
      })
    }),
    deleteLibraryCampaign: builder.mutation<void, string>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/${campaignId}`,
        method: "DELETE"
      })
    }),
    duplicateLibraryEmail: builder.mutation<void, { campaignId: string, emailId: string }>({
      query: ({ campaignId, emailId }) => ({
        url: `/v1/library/campaigns/${campaignId}/email/${emailId}/duplicate`,
        method: "PUT",
      })
    }),
    deleteLibraryEmail: builder.mutation<void, { campaignId: string, emailId: string }>({
      query: ({ campaignId, emailId }) => ({
        url: `/v1/library/campaigns/${campaignId}/email/${emailId}`,
        method: "DELETE"
      })
    }),
    getReviewLogCampaigns: builder.query<SimplifiedCampaignResponse, { type: string, reviewStatuses: string[] }>({
      query: ({ type, reviewStatuses }) => ({
        url: `/v1/library/campaigns/${type}`,
        method: "GET",
        params: reviewStatuses?.length
          ? { reviewStatuses }
          : null
      }),
    }),
    getApprovedCampaigns: builder.query<SimplifiedCampaignResponse, void>({
      query: () => ({
        url: `/v1/library/campaigns/startup`,
        method: "GET"
      }),
    }),
    getCampaignComments: builder.query<CampaignCommentResponse, string>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/comments?campaignId=${campaignId}`,
        method: "GET"
      })
    }),
    sendCampaignComment: builder.mutation<CampaignCommentResponse, CampaignCommentRequest>({
      query: (body) => ({
        url: `/v1/library/campaigns/comments`,
        method: "POST",
        body
      })
    }),
    replyForComment: builder.mutation<void, { body: { text: string }, commentId: string }>({
      query: ({ body, commentId }) => ({
        url: `/v1/library/campaigns/comments/${commentId}/reply`,
        method: "POST",
        body
      })
    }),
    changeCommentStatus: builder.mutation<void, { body: { status: string }, commentId: string }>({
      query: ({ body, commentId }) => ({
        url: `/v1/library/campaigns/comments/${commentId}`,
        method: "PUT",
        body
      })
    }),
    changeCampaignStatus: builder.mutation<void, { body: { status: string }, campaignId: string }>({
      query: ({ body, campaignId }) => ({
        url: `/v1/library/campaigns/${campaignId}/status`,
        method: "PUT",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getLibraryCampaigns = createApiCall<Campaign[], void>(myLibraryAPI.endpoints.getLibraryCampaigns);
export const createLibraryCampaign = createApiCall<void, CreateCampaignRequest>(myLibraryAPI.endpoints.createLibraryCampaign);
export const duplicateLibraryCampaign = createApiCall<Campaign, string>(myLibraryAPI.endpoints.duplicateLibraryCampaign);
export const deleteLibraryCampaign = createApiCall<void, string>(myLibraryAPI.endpoints.deleteLibraryCampaign);
export const duplicateLibraryEmail = createApiCall<{ id: string, campaignId: string }, { campaignId: string, emailId: string }>(myLibraryAPI.endpoints.duplicateLibraryEmail);
export const deleteLibraryEmail = createApiCall<void, { campaignId: string, emailId: string }>(myLibraryAPI.endpoints.deleteLibraryEmail);
export const getReviewLogCampaigns = createApiCall<SimplifiedCampaignResponse[], { type: string, reviewStatuses: string[] }>(myLibraryAPI.endpoints.getReviewLogCampaigns);
export const getApprovedCampaigns = createApiCall<SimplifiedCampaignResponse, void>(myLibraryAPI.endpoints.getApprovedCampaigns);
export const getCampaignComments = createApiCall<CampaignCommentResponse, string>(myLibraryAPI.endpoints.getCampaignComments);
export const sendCampaignComment = createApiCall<CampaignCommentResponse, CampaignCommentRequest>(myLibraryAPI.endpoints.sendCampaignComment);
export const replyForComment = createApiCall<void, CommentActionRequest>(myLibraryAPI.endpoints.replyForComment);
export const changeCommentStatus = createApiCall<void, CommentActionRequest>(myLibraryAPI.endpoints.changeCommentStatus);
export const changeCampaignStatus = createApiCall<void, CommentActionRequest>(myLibraryAPI.endpoints.changeCampaignStatus);
