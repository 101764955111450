import React from 'react';
import { TemplateAttributeTypes } from '../../../../../../interfaces/campaign';
import TextField from '../../../../../../components/main/CustomFields/TextField';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
}

const RenderTextInput: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
}) => {
  const classes = useStyles();

  const defaultPaceholder = item.attributes.find(item => item.type === TemplateAttributeTypes.PLACEHOLDER_ATTRIBUTE)?.text;
  const placeholder = item.attributes.find(item => (
    item.type === TemplateAttributeTypes.RESET_PLACEHOLDER_UNDER_CONDITION_ATTRIBUTE && values[Object.values(item.elementReference).join('-')] === item.option
  ))?.placeholder;

  const handleChange = (name, value) => {
    const minValue = item.attributes.find(item => item.type === TemplateAttributeTypes.MIN_VALUE_ATTRIBUTE)?.value;
    const maxValue = item.attributes.find(item => item.type === TemplateAttributeTypes.MAX_VALUE_ATTRIBUTE)?.value;

    if (value < minValue) {
      setFieldError(name, `Min ${minValue}`);
    }
    if (value > maxValue) {
      setFieldError(name, `Max ${maxValue}`);
    }

    setFieldValue(name, value);
  }

  return (
    <TextField
      name={name}
      value={values[name]}
      placeholder={placeholder || defaultPaceholder || ''}
      maxLength={item.attributes.find(item => item.type === TemplateAttributeTypes.MAX_LENGTH_ATTRIBUTE)?.value}
      error={touched[name] && errors[name]}
      onChange={(event) => handleChange(name, event.target.value)}
    />
  );
};

export default RenderTextInput;
