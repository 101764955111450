// Do not change anything in the protected area. Doing so will be detected and your commit will be rejected.

// Protected Area Start
import { Component } from "react";
import { IBlock } from "./IBlock";
import { runEngine } from "./RunEngine";
import { Message } from "./Message";
import MessageEnum, { getName } from "./Messages/MessageEnum";
import { Keyboard } from "react-native";
import { v4 as uuidv4 } from "uuid";
import * as helper from "./Helpers";
import StorageProvider from "./StorageProvider";
import { enqueueSnackbar } from "notistack";

export class BlockComponent<Props, S, SS> extends Component<Props, S, SS>
  implements IBlock {
  isLoaded = false;
  blockId: string;
  subScribedMessages: string[];
  send: (message: Message) => void;

  constructor(props: Props) {
    super(props);
    this.blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(this.blockId, message);
    this.subScribedMessages = [""];
    this.hideKeyboard = this.hideKeyboard.bind(this);
  }

  receive(from: string, message: Message): void { }

  async componentDidMount() {
    // const guestRoutes = [
    //   "/",
    //   "/login",
    //   "/signup",
    //   "/ChooseOption",
    //   "/letsgetstarted",
    //   "/ForgotPassword"
    // ];

    // const currentPathname = window.location.pathname;
    // const signUpEmail = window.localStorage.getItem("sign-up-email");
    // const uncompletedSignUpStep = window.localStorage.getItem(
    //   STEPS_TYPE.SIGN_UP
    // );
    // const uncompletedOnboardingStep = window.localStorage.getItem(
    //   STEPS_TYPE.ONBOARDING
    // );
    // const token = await StorageProvider.getItem("user-token");

    // // @ts-ignore
    // const { history } = this.props;

    // if (token) {
    //   if (guestRoutes.some((item) => item === currentPathname)) {
    //     history
    //       ? history.replace("/dashboard")
    //       : (window.location.href = "/dashboard");
    //   }

    //   // if (uncompletedSignUpStep || uncompletedOnboardingStep) {
    //   //   if (
    //   //     currentPathname !== '/before-start' &&
    //   //     currentPathname !== '/onboarding/about-the-company' &&
    //   //     currentPathname !== '/onboarding/about-your-approach' &&
    //   //     currentPathname !== '/onboarding/offering-the-company' &&
    //   //     currentPathname !== '/describle-business-plan' &&
    //   //     currentPathname !== '/tell-more-business' &&
    //   //     currentPathname !== '/company-about'
    //   //   ) {
    //   //     const redirectUrl =
    //   //       GET_URL_BY_STEP[uncompletedSignUpStep] ||
    //   //       GET_URL_BY_STEP[uncompletedOnboardingStep];

    //   //     history ? history.replace(redirectUrl) : window.location.href = redirectUrl;
    //   //   }
    //   // }

    //   // if (!signUpEmail && !uncompletedSignUpStep && !uncompletedOnboardingStep) {
    //   //   if (guestRoutes.some(item => item === currentPathname)) {
    //   //     history ? history.replace('/dashboard') : window.location.href = '/dashboard';
    //   //   }
    //   // }
    // } else if (!guestRoutes.some((item) => item === currentPathname)) {
    //   history ? history.replace("/") : (window.location.href = "/");
    // }

    // this.isLoaded = true;
  }

  async componentWillUnmount() {
    this.isLoaded = false;
    Keyboard.dismiss();
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  }

  public toggleState(objectID: string) {
    this.changeState(objectID, !this.getState(objectID));
  }

  public changeState(objectID: string, value: any) {
    switch (objectID) {
      default:
        break;
    }
  }

  public getState(objectID: string) {
    const testString = "";

    switch (objectID) {
      case "testBoolTrue":
        return true;
      case "testBoolFalse":
        return false;
      case "testString":
        return testString;
      default:
        return null;
    }
  }

  public processOnClickMessage(messageID: string, value: any = null) {
    switch (messageID) {
      default:
        break;
    }
  }

  public showAlert(
    title: string,
    error: string,
    btnPositiveText?: string,
    btnPositiveMessage?: Message,
    btnNegativeText?: string,
    btnNegativeMessage?: Message,
    btnNeutralText?: string,
    btnNeutralMessage?: Message
  ) {
    Keyboard.dismiss();

    if (!btnPositiveText && !btnNegativeText && !btnNeutralText) {
      btnPositiveText = "Ok";
    }

    const alertMsg: Message = new Message(getName(MessageEnum.AlertMessage));
    alertMsg.addData(getName(MessageEnum.AlertTitleMessage), title);
    alertMsg.addData(getName(MessageEnum.AlertBodyMessage), error);
    alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    alertMsg.addData(
      getName(MessageEnum.AlertButtonPositiveText),
      btnPositiveText
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNegativeText),
      btnNegativeText
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNeutralText),
      btnNeutralText
    );

    alertMsg.addData(
      getName(MessageEnum.AlertButtonPositiveMessage),
      btnPositiveMessage
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNegativeMessage),
      btnNegativeMessage
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNeutralMessage),
      btnNeutralMessage
    );

    runEngine.sendMessage(alertMsg.id, alertMsg);
  }

  public parseApiErrorResponse(responseJson: any) {
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: any[] = responseJson.errors;

    let allerrors = "";
    errors.map((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${allerrors}{\n}${value}`;
          }
        }
      });
    });

    enqueueSnackbar(
      allerrors,
      { variant: 'error' }
    );

    this.showAlert("Error", allerrors);
  }

  public isPlatformWeb() {
    return helper.getOS() === "web";
  }

  public isPlatformiOS() {
    return helper.getOS() === "ios";
  }

  public isPlatformAndroid() {
    return helper.getOS() === "android";
  }

  public parseApiCatchErrorResponse(errorReponse: any) {
    if (errorReponse) {
      this.showAlert(
        "Error",
        JSON.stringify(errorReponse).replace(new RegExp('"', "g"), "")
      );
    }
  }

  public hideKeyboard() {
    if (!this.isPlatformWeb()) {
      Keyboard.dismiss();
    }
  }
}

// Protected Area End
