import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, Button, Checkbox, CircularProgress, FormHelperText, InputAdornment, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import TextField from "../../../components/main/CustomFields/TextField";
import CheckboxCheckedIcon from '../../../assets/icons/checkbox_checked.svg';
import CheckboxIcon from '../../../assets/icons/checkbox.svg';
import { getProfile } from "../../../redux/services/profile";
import { clearStorages } from "../../../helpers/other";
import { confirmOtpCode, signUpUser } from "../../../redux/services/authentication";
import StorageProvider from "../../../framework/main/StorageProvider";
import { removeProfile } from "../../../redux/features/profile";
import { useDispatch } from "react-redux";
import { notify } from "../../../providers/notification";

import { useStyles } from "./styles";

const VerifySignUpCode = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const formRef = useRef(null);

  useEffect(() => {
    handleGetEmail();
  }, []);

  useEffect(() => {
    if (formRef) {
      formRef.current.setFieldValue('email', email)
    }
  }, [email, formRef]);

  const handleGetEmail = async () => {
    setLoading(true);

    const email = await StorageProvider.getItem("user-email");

    if (email?.length) {
      setEmail(email);
    } else {
      await clearStorages();

      history.push('/signup');
    }

    setLoading(false);
  }

  // const handleGetProfile = async () => {
  //   setLoading(true);

  //   const { data, error } = await getProfile(null, true);

  //   if (data && !error) {
  //     setProfile(data);
  //   } else {
  //     history.push('/login');
  //   }

  //   setLoading(false);
  // }

  const handleSubmitVerificationCode = async (otp) => {
    setLoading(true);

    if (email?.length) {
      const { error } = await confirmOtpCode({
        email,
        otp
      });

      if (!error) {
        await StorageProvider.setItem("user-email", email);
        await StorageProvider.setItem("user-otp", otp);
        // await StorageProvider.setItem("user-token", data.meta.token);
        // await StorageProvider.setItem("user-refresh-token", data.meta.refresh_token);

        history.push('/account-details');
      } else {
        notify.error('Incorrect otp code');
      }
    } else {
      history.push('/');
    }

    setLoading(false);
  }

  const handleLogin = async () => {
    await clearStorages();

    history.push('/login');
  }

  const handleSendOtpCode = async () => {
    setLoading(true);

    if (email?.length) {
      await signUpUser({ email });
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.containerStyle}>
        <Box className={classes.signupform}>
          <Formik
            innerRef={formRef}
            initialValues={{
              email: email,
              signupCode: "",
              termsAndConditions: false,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email address is not valid.")
                .required("Email address is required."),
              signupCode: Yup.number()
                .required("Signup code should not be blank."),
              termsAndConditions: Yup.boolean()
                .oneOf([true], "Please accept the Privacy Policy and Terms of Use.")
                .required("Please accept the Privacy Policy and Terms of Use.")
            })}
            onSubmit={(values) => handleSubmitVerificationCode(values.signupCode)}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue
            }) => (
              <Form
                translate={undefined}
                style={{
                  marginTop: '32px'
                }}
              >
                <Typography
                  align="center"
                  className={classes.typographyStyle1}
                  variant="h6"
                >
                  Sign up
                </Typography>
                <Typography
                  align="center"
                  className={classes.typographyStyle2}
                  variant="h6"
                >
                  Create an account to access Pebbles Ai.
                </Typography>
                <Typography
                  align="center"
                  className={classes.typographyStyle2}
                  variant="h6"
                >
                  Already have an account?{" "}
                  <Link
                    onClick={() => handleLogin()}
                    className={classes.link}
                  >
                    Log in
                  </Link>
                </Typography>
                <Box>
                  <TextField
                    name={'email'}
                    value={values['email']}
                    label={'Email'}
                    placeholder={'Your email'}
                    error={(touched['email'] && errors['email']) as string}
                    disabled
                    onChange={() => { }}
                  />
                  <Box className={classes.subHeadingWrapper}>
                    <Typography
                      className={classes.subHeadingtypographyStyle1}
                    >
                      We just sent you a <b>temporary sign up code</b>.
                      <Typography
                        className={classes.subHeadingtypographyStyle1}
                      >
                        Please check your <b>inbox</b> and paste the sign up
                        code below.
                      </Typography>
                    </Typography>
                  </Box>
                  <TextField
                    name={'signupCode'}
                    value={values['signupCode']}
                    label={'Sign up code'}
                    placeholder={'Paste sign up code'}
                    error={(touched['signupCode'] && errors['signupCode'])}
                    type={'number'}
                    onChange={(e) => setFieldValue('signupCode', e.target.value)}
                  />
                  <Typography className={classes.tnc}>
                    <Field
                      as={Checkbox}
                      error={
                        !!(
                          errors.termsAndConditions &&
                          touched.termsAndConditions
                        )
                      }
                      value={values['termsAndConditions']}
                      name="termsAndConditions"
                      checkedIcon={<img width={20} height={20} src={CheckboxCheckedIcon} />} //custom checknbox
                      icon={<img width={20} height={20} src={CheckboxIcon} />} //customcheckbox icon
                      onClick={() => setFieldValue('termsAndConditions', !values['termsAndConditions'])}
                    />
                    <Typography className={classes.termsAndCondition}>
                      I agree to the{" "}
                      <Link
                        className={classes.link}
                        href={'/privacy-policy'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy{" "}
                      </Link>
                      {" & "}
                      <Link
                        className={classes.link}
                        href={'/terms-and-conditions'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions{" "}
                      </Link>
                    </Typography>
                  </Typography>
                  {!!touched.termsAndConditions && !!errors.termsAndConditions && (
                    <FormHelperText error>
                      {errors.termsAndConditions}
                    </FormHelperText>
                  )}
                  <Button
                    fullWidth
                    type="submit"
                    data-test-id="VerifySignUpCodeSubmitBtnDataTestId"
                    className={classes.submitBtn}
                  >
                    Continue with email
                  </Button>
                  <Typography
                    data-test-id="VerifySignUpCodeResendBtnDataTestId"
                    align="center"
                    className={classes.receiveCode}
                    onClick={() => handleSendOtpCode()}
                  >
                    Didn’t receive code?{" "}
                    <Link className={classes.link}>Resend</Link>
                  </Typography>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default VerifySignUpCode;
