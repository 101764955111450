import { isDateInPast } from "./date";

export const getCurrentSubscriptionStatus = (subscription) => {
  if (subscription) {
    if (isDateInPast(subscription.validTill) && !subscription.isActive) {
      return 'EXPIRED';
    }

    if (!isDateInPast(subscription.validTill) && subscription.isActive) {
      return 'ACTIVE';
    }

    if (!subscription.isActive && !isDateInPast(subscription.validTill)) {
      return 'CANCELED';
    }

    // if (!isDateInPast(subscription.validTill) || subscription.isActive) {
    //   return 'ACTIVE';
    // } else if (isDateInPast(subscription.validTill)) {
    //   return 'EXPIRED';
    // } else {
    //   return 'CANCELED';
    // }
  } else {
    return 'ABSENT';
  }
}
