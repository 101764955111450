import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Avatar, Box, Button, IconButton, TableCell, TableRow, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/email_icon.svg';
import { REVIEW_STATUS } from '../../../../utils/enums';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

interface Props {
  item: any;
}

const LogTableRow: React.FC<Props> = ({
  item,
}) => {
  const history = useHistory();
  const classes = useStyles({ item });

  const userTimezone = moment.tz.guess();

  const getCampaignReviewStatusMetadata = (status) => {
    switch (status) {
      case REVIEW_STATUS.DRAFT:
        return {
          text: 'Draft',
          color: '#F8FAFC',
          dotColor: '#94A3B8',
        }
      case REVIEW_STATUS.PENDING_REVIEW:
        return {
          text: 'Pending review',
          color: '#F8FAFC',
          dotColor: '#94A3B8',
        }
      case REVIEW_STATUS.CHANGE_REQUIRED:
        return {
          text: 'Changes required',
          color: '#FEF3C7',
          dotColor: '#F59E0B',
        }
      case REVIEW_STATUS.REJECTED:
        return {
          text: 'Rejected',
          color: '#FEE2E2',
          dotColor: '#F87171',
        }
      default:
        return {
          text: 'Approved',
          color: '#D1FAE5',
          dotColor: '#34D399',
        }
    }
  }

  const getCampaignUpdatedText = (updatedAt) => {
    const now = moment();
    const updatedAtMoment = moment.utc(updatedAt).tz(userTimezone);

    const diffInMinutes = now.diff(updatedAtMoment, 'minutes');
    const diffInHours = now.diff(updatedAtMoment, 'hours');

    if (diffInMinutes <= 0) {
      return 'just now';
    } else if (diffInMinutes > 0 && diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInMinutes >= 60 && diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else {
      return updatedAtMoment.format('DD-MMM-YYYY HH:mm');
    }
  };

  return (
    <>
      <TableRow
        className={classes.tableRow}
      >
        <TableCell
          align="left"
          className={classes.tableCellMain}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <EmailIcon
              width={14}
              height={12}
            />
            <Typography
              noWrap
              style={{
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '21px'
              }}
            >
              {item.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCellMain}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Avatar
              src={item.userImageUrl}
              alt={item.userName}
              style={{
                width: '28px',
                height: '28px'
              }}
            />
            <Typography
              noWrap
              style={{
                fontSize: '14px',
                fontWeight: '500',
                lineHeight: '16px',
              }}
            >
              {item.userName}
            </Typography>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell}
        // style={{
        //   width: '20%',
        // }}
        >
          <Typography
            style={{
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '12px',
              color: '#475569'
            }}
          >
            {getCampaignUpdatedText(item.updatedAt)}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell}
        // style={{
        //   width: '20%',
        // }}
        >
          <Typography
            noWrap
            style={{
              width: 'fit-content',
              minWidth: '150px',
              fontSize: '14px',
              fontWeight: '600',
              lineHeight: '21px',
              background: getCampaignReviewStatusMetadata(item.reviewStatus).color,
              borderRadius: '40px',
              padding: '6px 14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px'
            }}
          >
            <Box
              style={{
                width: '8px',
                height: '8px',
                background: getCampaignReviewStatusMetadata(item.reviewStatus).dotColor,
                borderRadius: '50%'
              }}
            />
            {getCampaignReviewStatusMetadata(item.reviewStatus).text}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={classes.tableCell}
          style={{
            paddingRight: '8px'
            // width: '10%',
          }}
        >
          <Box className={classes.optionsBox}>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => history.push(`/tactical-outreach/email?campaign_id=${item.id}`)}
            >
              Open
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default LogTableRow;
