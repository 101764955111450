import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0px, -50%)',
    '& svg': {
      width: '9px',
      height: '9px',
      '& path': {
        fill: '#475569'
      }
    }
  }
}));
