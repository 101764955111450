import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  filters: {
    width: '100%',
    minWidth: '880px',
    // maxWidth: '1080px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
    margin: '0 auto'
  },
  filtersLeftBlock: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  filtersRightBlock: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px'
  },
}));
