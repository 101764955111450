export interface ChatSimplified {
  id: string;
  title: string;
  createdAt?: Date;
}

export interface Chat extends ChatSimplified {
  accountId?: string;
  sessionId?: string;
  messages?: Message[];
}

export interface Message {
  id: string;
  answerStatus: AnswerStatus;
  author?: MessageAuthorTypes;
  question?: string;
  answer?: string;
  message?: string | "error";
  timestamp?: Date;
}

export interface SendChatMessageResponse {
  answer: string;
  messageId: string;
  sessionId: string;
}

export interface ChatRequest {
  question: string;
  chatType: string;
}

export interface LikeDislikeRequest {
  sessionId: string;
  messageId: string;
  tab: string;
}

export interface CreateChatRequest {
  body: ChatRequest;
  tab: string;
  signal?: AbortSignal;
}

export interface SendChatMessageRequest extends CreateChatRequest {
  sessionId: string;
}

export interface GetChatByIdRequest {
  id: string;
  tab: string;
}

export enum AnswerStatus {
  UNEVALUATED = "UNEVALUATED",
  LIKED = "LIKED",
  DISLIKED = "DISLIKED"
}

export enum MessageAuthorTypes {
  USER = "user",
  COPILOT = "copilot"
}

export enum CopilotTypes {
  GENERAL = "general",
  INTELLIGENCE = "intelligence"
}
