import { api, createApiCall } from "../api";
import { Chat, ChatRequest, ChatSimplified, CreateChatRequest, GetChatByIdRequest, LikeDislikeRequest, SendChatMessageRequest, SendChatMessageResponse } from "../../interfaces/copilot";

const copilotAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query<ChatSimplified[], string>({
      query: (tab) => ({
        url: `/v1/chatbot/${tab}`,
        method: "GET"
      }),
    }),
    getChatById: builder.query<Chat, { id: string, tab: string }>({
      query: ({ id, tab }) => ({
        url: `/v1/chatbot/${tab}/${id}`,
        method: "GET"
      }),
    }),
    sendChatQuestion: builder.mutation<Chat, {
      body: ChatRequest,
      tab: string,
      signal?: AbortSignal
    }>({
      query: ({ body, tab, signal }) => ({
        url: `/v1/chatbot/${tab}`,
        method: "POST",
        body,
        signal
      })
    }),
    sendChatQuestionById: builder.mutation<Chat, {
      body: ChatRequest,
      sessionId: string,
      tab: string,
      signal?: AbortSignal
    }>({
      query: ({ body, sessionId, tab, signal }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}`,
        method: "PUT",
        body, // { "question": "string", chatType: "string" }
        signal
      })
    }),
    deleteChat: builder.mutation<void, { id: string, tab: string }>({
      query: ({ id, tab }) => ({
        url: `/v1/chatbot/${tab}/${id}`,
        method: "DELETE"
      })
    }),
    likeMessage: builder.mutation<void, { sessionId: string, messageId: string, tab: string }>({
      query: ({ sessionId, messageId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/like/${messageId}`,
        method: "POST"
      })
    }),
    dislikeMessage: builder.mutation<void, { sessionId: string, messageId: string, tab: string }>({
      query: ({ sessionId, messageId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/dislike/${messageId}`,
        method: "POST"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getChats = createApiCall<ChatSimplified[], string>(copilotAPI.endpoints.getChats);
export const getChatById = createApiCall<Chat, GetChatByIdRequest>(copilotAPI.endpoints.getChatById);
export const sendChatQuestion = createApiCall<Chat, CreateChatRequest>(copilotAPI.endpoints.sendChatQuestion);
export const sendChatQuestionById = createApiCall<SendChatMessageResponse, SendChatMessageRequest>(copilotAPI.endpoints.sendChatQuestionById);
export const deleteChat = createApiCall<void, GetChatByIdRequest>(copilotAPI.endpoints.deleteChat);
export const likeMessage = createApiCall<void, LikeDislikeRequest>(copilotAPI.endpoints.likeMessage);
export const dislikeMessage = createApiCall<void, LikeDislikeRequest>(copilotAPI.endpoints.dislikeMessage);
