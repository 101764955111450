import React, { useEffect, useRef, useState } from 'react';
import RevolutCheckout from '@revolut/checkout'
import { Form, Formik } from 'formik';
import { Box, Button, CircularProgress, Typography, TextField as Input, FormHelperText } from '@material-ui/core';
import * as Yup from "yup";
import TextField from '../../../../../CustomFields/TextField';
import { getProfile, updateProfile } from '../../../../../../../redux/services/profile';
import { Autocomplete, Skeleton } from '@material-ui/lab'
import SuccessPayment from '../SuccessPayment';
import ErrorPayment from '../ErrorPayment';
import { COUNTRIES } from '../../../../../../../utils/countries';
import { useHistory } from 'react-router-dom';
import { formatCurrency, getCurrencySybmol } from '../../../../../../../helpers/curency';
import { applyOrderTax, getTaxInfo, saveTaxInfo } from '../../../../../../../redux/services/subscriptions';
import { notify } from '../../../../../../../providers/notification';
import { ProfileResponse } from '../../../../../../../interfaces/profile';

import { useStyles } from './styles';

interface Props {
  order: any;
  redirect?: boolean;
  setOrder?: (order: any) => void;
  onHideParentModal?: (hide: boolean) => void;
  onRenewSubscription?: () => void;
  onClose: (closeParentModal: boolean) => void;
}

const PaymentMethod: React.FC<Props> = (props) => {
  const {
    order,
    redirect = true,
    setOrder = () => {},
    onRenewSubscription = () => {},
    onHideParentModal = () => {},
    onClose = () => {},
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [revolut, setRevolut] = useState<any>(null);
  const [card, setCard] = useState<any>(null);
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const [cardError, setCardError] = useState<string>('');
  const [cardStatuses, setCardStatuses] = useState<any>({
    autofilled: false,
    completed: false,
    empty: true,
    focused: false,
    invalid: false
  });
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();
  const fields = useRef<any>(null);
  const formRef = useRef<any>(null);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setLoading(true);

    handleGetProfile();
  }, []);

  useEffect(() => {
    if (fields && order.token) {
      handleCreateRevolut();
    }
  }, [fields, order.token]);

  useEffect(() => {
    if (revolut) {
      handleCreateCardFields();
    }
  }, [revolut]);

  useEffect(() => {
    if (profile && formRef.current) {
      handleSetDefaultValues();
    }
  }, [profile, formRef]);

  const handleGetProfile = async () => {
    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    }

    setLoading(false);
  }

  const handleCreateRevolut = async () => {
    const revolut = await RevolutCheckout(
      order?.token,
      process.env.REACT_APP_ENVIRONMENT === 'development' ? 'sandbox' : 'prod',
    );

    setRevolut(revolut);
  }

  const handleSetDefaultValues = async () => {
    let defaultValues = {
      email: profile.user.email,
      cardholderName: profile.user.name,
      country: { value: Object.keys(COUNTRIES).find(key => COUNTRIES[key] === profile.account.countryName), label: profile.account.countryName },
      zip: null,
      tax: null
    };

    const { data, error } = await getTaxInfo();

    if (data && !error) {
      defaultValues = {
        ...defaultValues,
        country: data.location
          ? { value: Object.keys(COUNTRIES).find(key => COUNTRIES[key] === data.location), label: data.location }
          : null,
        zip: data.zipCode,
        tax: data.taxId
      }
    }

    formRef.current.setValues(defaultValues);
  }

  const handleCreateCardFields = () => {
    const card = revolut.createCardField({
      target: fields.current,
      hidePostcodeField: true,
      savePaymentMethodFor: 'merchant',
      savePaymentMethodForMerchant: true,
      onSuccess() {
        setShowSuccessModal(true);
        onHideParentModal(false);
        setLoading(false);
      },
      onError(error) {
        setShowErrorModal(true);
        onHideParentModal(false);
        setLoading(false);

        notify.error(error?.['message'] || 'Something went wrong, please contact our support');
      },
      onValidation(errors) {
        setCardError(
          Object.values(errors)[0]?.['message'] || ''
        )
      },
      onStatusChange(statuses) {
        setCardStatuses(statuses);
      },
      onCancel() {
        setShowErrorModal(true);
        onHideParentModal(false);
        setLoading(false);
      },
      styles: {
        default: {
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '21px',
          color: 'black',
          '::placeholder': {
            color: '#8792A2',
          },
        },
        invalid: {
          color: '#fa755a',
          '::placeholder': {
            color: '#fa755a',
          },
        },
      },
      classes: {}
    });

    setCard(card);
    setLoading(false);
  }

  const handleSubmit = async (values) => {
    if (cardStatuses.completed && !cardStatuses.invalid && !cardStatuses.empty) {
      setLoading(true);
      getProfile(null, true);
      onHideParentModal(true);

      card.submit({
        name: values.cardholderName.trim(),
        email: values.email.trim(),
        // phone?: string;
        savePaymentMethodFor: 'merchant',
        savePaymentMethodForMerchant: true,
        cardholderName: values.cardholderName.trim(),
        billingAddress: {
          countryCode: values.country.value,
          postcode: values.zip,
          // region?: string;
          // city?: string;
          // streetLine1?: string;
          // streetLine2?: string;
        },
        shippingAddress: {
          countryCode: values.country.value,
          postcode: values.zip,
          // region?: string;
          // city?: string;
          // streetLine1?: string;
          // streetLine2?: string;
        },
      });
    }
  }

  const handleRedirect = () => {
    setLoading(true);

    setShowSuccessModal(false);
    setShowErrorModal(false);

    if (order.renewSubscriptionAfter) {
      onRenewSubscription();
    }

    if (redirect) {
      const url = window.location.pathname;

      if (url.includes('/choose-your-plan')) {
        history.push('/before-start');
      } else {
        history.push('/dashboard');
      }
    }

    setLoading(false);
    onClose(true);
  }

  const debounceFieldSave = (field: string, value: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      formRef.current.setFieldValue(field, value);
      handleSaveTaxInfo(formRef.current.values);
    }, 500);
  };

  const handleFieldBlur = (field: string) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    handleSaveTaxInfo(formRef.current.values);
  };

  const handleSaveTaxInfo = async (values) => {
    await saveTaxInfo({
      accountId: profile.account.uuid,
      location: values.country.label,
      zipCode: values.zip,
      taxId: values.tax
    });
  }

  const handleApplyOrderTax = async () => {
    if (order?.amount !== 0) {
      setOrder({
        ...order,
        token: null
      });

      const { data, error } = await applyOrderTax(order.id);

      if (data && !error) {
        setOrder({
          ...data,
          token: order.token
        });
      }
    }
  }

  return (
    <>
      {loading && (
        <Box className={classes.loader}>
          <CircularProgress color="primary" />
        </Box>
      )}

      {order && (
        <Formik
          innerRef={formRef}
          initialValues={{}}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Invalid email address')
              .required('This field is required'),
            cardholderName: Yup.string()
              .matches(/^[a-zA-Z\s\-']+$/, 'Cardholder name can only contain letters, spaces, hyphens, and apostrophes')
              // .test(
              //   'min-words',
              //   'Enter correct cardholder name',
              //   value => value && value.split(' ').filter(word => word.length >= 3).length >= 2
              // )
              .max(1000, 'Max 1000 symbols')
              .required('Cardholder name is required'),
            country: Yup.object().shape({
              value: Yup.string().required('Country value is required'),
              label: Yup.string().required('Country label is required')
            }).required('Country is required'),
            zip: Yup.string()
              .required('Zip code is required')
              .matches(/^[a-zA-Z0-9\s-]{3,10}$/, 'Invalid zip code format')
              .nullable(),
            tax: Yup.string()
              // .required('Tax number is required')
              .matches(/^[A-Z]{2}[0-9]{8,12}$/, 'Invalid tax number format')
              .nullable(),
          })}
          validateOnChange={true}
          validateOnBlur={false}
          isInitialValid={false}
          onSubmit={(values: any) => handleSubmit(values)}
        >
          {({
            values,
            errors,
            touched,
            submitForm,
            validateField,
            validateForm,
            resetForm,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => (
            <Form
              translate={undefined}
              style={{ width: '100%' }}
            >
              <Box className={classes.container}>
                <TextField
                  name={'email'}
                  value={values['email']}
                  placeholder={'Provide an email address'}
                  label={'Email'}
                  error={(touched['email'] && errors['email']) as string}
                  onChange={(event) => setFieldValue('email', event.target.value)}
                />
                <Box className={classes.cardWrapper}>
                  <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', marginBottom: '4px' }}>
                    Enter card number and info
                  </Typography>
                  <div ref={fields} className={classes.cardFields} />
                  {!!cardError.length && (
                    <FormHelperText error>
                      {cardError}
                    </FormHelperText>
                  )}
                </Box>
                <TextField
                  name={'cardholderName'}
                  value={values['cardholderName']}
                  placeholder={'Provide the name of card'}
                  label={'Cardholder name'}
                  error={(touched['cardholderName'] && errors['cardholderName']) as string}
                  onChange={(event) => setFieldValue('cardholderName', event.target.value)}
                />
                <Box style={{ width: '100%', display: 'flex', gap: '8px' }}>
                  <Box style={{ width: '49%', minWidth: '49%' }}>
                    <Typography style={{ marginBottom: '4px', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                      Country
                    </Typography>
                    <Autocomplete
                      value={values['country'] || ''}  // Ensure value is always defined
                      options={Object.entries(COUNTRIES).map(([key, value]) => ({ value: key, label: value }))}
                      getOptionLabel={(option: any) => option.label || ''}
                      onChange={async (event: any, value: any) => {
                        setFieldValue('country', value);
                        setFieldValue('zip', null);
                        setFieldValue('tax', null);

                        await handleSaveTaxInfo({
                          ...values,
                          country: value,
                          zip: null,
                          tax: null
                        });
                        handleApplyOrderTax();
                      }}
                      disableClearable
                      autoHighlight
                      className={classes.contryAutocomplete}
                      renderInput={(params: any) => (
                        <Input
                          {...params}
                          name={'country'}
                          placeholder={'Choose country...'}
                        />
                      )}
                    />
                  </Box>
                  <TextField
                    name={'zip'}
                    value={values['zip']}
                    placeholder={'ZIP'}
                    label={'Zip code'}
                    className={classes.zipField}
                    error={(touched['zip'] && errors['zip']) as string}
                    onChange={(event) => {
                      setFieldValue('zip', event.target.value);
                      debounceFieldSave('zip', event.target.value);
                    }}
                    onBlur={() => handleFieldBlur('zip')}
                  />
                </Box>
                <TextField
                  name={'tax'}
                  value={values['tax']}
                  placeholder={'DE123456789'}
                  label={'Company Tax ID'}
                  error={(touched['tax'] && errors['tax']) as string}
                  onChange={(event) => {
                    setFieldValue('tax', event.target.value);
                    debounceFieldSave('tax', event.target.value)
                  }}
                  onBlur={() => handleFieldBlur('tax')}
                />
                {order?.amount !== 0
                  ? <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    type='submit'
                    onClick={() => card.validate()}
                  >
                    Pay {getCurrencySybmol(order?.currency)} {formatCurrency(order?.amount)}
                  </Button>
                  : <Box className={classes.footer}>
                    <Button
                      fullWidth
                      color='secondary'
                      variant='outlined'
                      onClick={() => onClose(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      color='primary'
                      variant='contained'
                      type='submit'
                      onClick={() => {
                        validateForm();
                        card.validate();
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                }
              </Box>
            </Form>
          )}
        </Formik>
      )}

      <SuccessPayment
        open={showSuccessModal}
        order={order}
        title={
          order.amount !== 0
            ? 'Payment successful!'
            : 'Payment method has been changed!'
        }
        onClose={() => handleRedirect()}
      />

      <ErrorPayment
        open={showErrorModal}
        title={
          order.amount !== 0
            ? 'Your order has been declined'
            : 'Unable to change payment method'
        }
        onClose={() => setShowErrorModal(false)}
        onTryAgain={() => handleSubmit(formRef.current.values)}
        onPayLater={() => handleRedirect()}
      />
    </>
  );
};

export default PaymentMethod;

