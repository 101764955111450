import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Avatar, Box, Button, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { ReactComponent as SendMessageIcon } from '../../../../../../assets/icons/send_message_icon.svg';
import { getProfile } from "../../../../../../redux/services/profile";
import { lastActivityDateFormat } from "../../../../../../helpers/date";
import TextField from "../../../../../../components/main/CustomFields/TextField";
import { ReactComponent as ThreeDotsIcons } from '../../../../../../assets/icons/three_dots.svg';
import { ReactComponent as CheckmarkIcons } from '../../../../../../assets/icons/plan_benefit_checkmark.svg';
import { AvatarGroup } from "@material-ui/lab";
import { showViewForAdmin, showViewForEditor, showViewForReviewer } from "../../../../../../helpers/roles";
import { notify } from "../../../../../../providers/notification";
import { changeCommentStatus, replyForComment, sendCampaignComment } from "../../../../../../redux/services/my-library";
import { ProfileResponse } from "../../../../../../interfaces/profile";

import { useStyles } from "./styles";

const CommentsList = ({
  campaign,
  email,
  subscription,
  handleGetCampaign,
  setCampaign
}) => {
  const [profile, setProfile] = useState<ProfileResponse | null>(null);
  const [currentComment, setCurrentComment] = useState<any>(null);
  const [commentMessage, setCommentMessage] = useState<string>('');
  const [menuAnchor, setMenuAnchor] = React.useState<null | { anchor: HTMLElement, id: string }>(null);
  const [loadingId, setLoadingId] = React.useState<null | string>(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    handleGetProfile();
  }, []);

  useEffect(() => {
    if ((showViewForEditor(subscription) && campaign.createdBy === profile?.user?.uuid) || showViewForReviewer(subscription) || showViewForAdmin(subscription)) {
      const newCommentTemplate = campaign.comments.find(item => item.status === "NEW");

      if (newCommentTemplate) {
        setCurrentComment(newCommentTemplate);
      } else if (!currentComment) {
        const openComments = campaign.comments.filter(item => item.status === 'OPEN');
        const lastOpenComment = openComments[openComments.length - 1];
  
        setCurrentComment(lastOpenComment);
      }
    }
  }, [campaign.comments, profile, currentComment]);

  const handleGetProfile = async () => {
    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleSendComment = async () => {
    if (commentMessage.trim().length) {
      const oldComments = campaign.comments;
      setCommentMessage('');

      setCampaign({
        ...campaign,
        comments: [
          ...campaign.comments.filter(item => item.status !== 'NEW'),
          {
            campaignId: campaign.id,
            createdAt: (new Date()).toISOString(),
            id: uuidv4(),
            ownerId: profile.user.uuid,
            status: 'OPEN',
            comment: {
              text: commentMessage.trim(),
              userId: profile.user.uuid,
              replies: []
            },
            metadata: {
              userId: profile.user.uuid,
              userImageUrl: profile.user.profilePhoto,
              userName: profile.user.name,
            }
          }
        ]
      })

      const { error } = await sendCampaignComment({
        campaignId: campaign.id,
        text: commentMessage.trim()
      });

      if (!error) {
        await handleGetCampaign(campaign.id, email.id);
      } else {
        notify.error('Failed to send the comment');

        setCampaign({
          ...campaign,
          comments: oldComments
        })
      }
    }
  }

  const handleReplyForComment = async () => {
    if (commentMessage.trim().length) {
      // setLoadingId(currentComment.id);
      const oldComments = campaign.comments;
      setCommentMessage('');

      setCampaign({
        ...campaign,
        comments: campaign.comments.map(item => (
          currentComment.id === item.id
            ? {
                ...item,
                comment: {
                  ...item.comment,
                  replies: [
                    ...item.comment.replies,
                    {
                      createdAt: (new Date()).toISOString(),
                      ownerId: profile.user.uuid,
                      text: commentMessage.trim(),
                      metadata: {
                        userId: profile.user.uuid,
                        userImageUrl: profile.user.profilePhoto,
                        userName: profile.user.name,
                      }
                    }
                  ]
                }
              }
            : item
        ))
      })

      const { error } = await replyForComment({
        body: {
          text: commentMessage.trim()
        },
        commentId: currentComment.id,
      });

      if (!error) {
        await handleGetCampaign(campaign.id, email.id);
      } else {
        notify.error('Failed to send the reply');

        setCampaign({
          ...campaign,
          comments: oldComments
        })
      }

      // setLoadingId(null);
    }
  }

  const handleFilterUniqueReplies = (replies) => {
    const result = replies?.reduce((unique, item) => {
      if (!unique.some(obj => obj.ownerId === item.ownerId)) {
        unique.push(item);
      }
      return unique;
    }, []);

    return result;
  }

  const handleChangeCommentStatus = async (comment) => {
    setLoadingId(comment.id);

    const { error } = await changeCommentStatus({
      body: {
        status: comment.status === 'OPEN' ? 'RESOLVED' : 'OPEN'
      },
      commentId: comment.id
    });

    if (!error) {
      setMenuAnchor(null);
      await handleGetCampaign(campaign.id, email.id);
    } else {
      notify.error(
        comment.status === 'OPEN'
          ? 'Failed to resolve the issue'
          : 'Failed to reopen the issue'
      );
    }

    setLoadingId(null);
  }

  return (
    <>
      {!!campaign.comments.length
        ? campaign.comments.map((item, index) => (
            item.status !== "NEW"
              ? <Box
                  key={index}
                  className={`
                            ${classes.commentItem} 
                            ${item.id === currentComment?.id ? classes.commentItemActive : ''}
                            ${item.status === 'RESOLVED' ? classes.commentItemResolved : ''}
                          `}
                >
                  {loadingId === item.id && (
                    <Box className={classes.commentItemActionLoader}>
                      <CircularProgress color="secondary" />
                    </Box>
                  )}
                  {/* {item.status === 'RESOLVED' && (
                            <CheckmarkIcons
                              style={{
                                height: '7px',
                                color: '#475569',
                                position: 'absolute',
                                top: 20,
                                right: 40,
                              }}
                            />
                          )} */}
                  {item.status !== 'NEW' && (
                    <IconButton
                      aria-controls={!!menuAnchor ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={!!menuAnchor ? 'true' : undefined}
                      className={classes.commentItemMenuIcon}
                      onClick={(event) => {
                        setMenuAnchor({
                          anchor: event.currentTarget,
                          id: item.id
                        })
                        // setCurrentComment(item)
                      }}
                    >
                      <ThreeDotsIcons />
                    </IconButton>
                  )}
                  {menuAnchor?.id === item.id && (
                    <Menu
                      anchorEl={menuAnchor.anchor}
                      open={!!menuAnchor}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      className={classes.commentItemMenuBackdrop}
                      onClose={() => setMenuAnchor(null)}
                    >
                      <MenuItem
                        onClick={() => handleChangeCommentStatus(item)}
                        style={{ fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#000000' }}
                      >
                        {item.status === 'OPEN'
                          ? 'Resolve'
                          : 'Reopen'
                        }
                      </MenuItem>
                    </Menu>
                  )}
                  <Box style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '8px' }}>
                    <Avatar
                      src={item.metadata.userImageUrl}
                      alt={item.metadata.userName}
                      style={{ width: '40px', height: '40px' }}
                    />
                    <Box>
                      <Typography noWrap style={{ maxWidth: '120px', fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
                        {item.metadata.userName}
                      </Typography>
                      <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '12px', color: '#475569' }}>
                        {lastActivityDateFormat(item.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#1E293B' }}>
                    {item.comment.text}
                  </Typography>
                  {item.id !== currentComment?.id && (
                    !!item.comment.replies?.length
                      ? <Box
                          className={classes.replyCounter}
                          onClick={() => setCurrentComment(item)}
                        >
                          <AvatarGroup
                            max={4}
                            spacing="small"
                            style={{ zoom: '0.5' }}
                          >
                            {handleFilterUniqueReplies(item.comment.replies)?.map((replyItem, replyIndex) => (
                              <Avatar
                                key={replyIndex}
                                src={replyItem.metadata.userImageUrl}
                                alt={replyItem.metadata.userName}
                              />
                            ))}
                          </AvatarGroup>
                          <Box className={classes.replyCounterText}>
                            {item.comment.replies?.length} repl{item.comment.replies?.length > 1 ? 'ies' : 'y'}
                          </Box>
                        </Box>
                      : item.status === 'OPEN'
                        ? <Box
                            className={classes.replyCounterText}
                            onClick={() => setCurrentComment(item)}
                          >
                            Reply in thread
                          </Box>
                        : null
                  )}
                  {item.id === currentComment?.id && (
                    <>
                      <Box className={classes.nestedItemsWrapper}>
                        {item.comment.replies?.map((nestedItem, nestedIndex) => (
                          <Box
                            key={nestedIndex}
                            className={`
                                      ${classes.nestedItem} 
                                      ${item.status === 'RESOLVED' ? classes.commentItemResolved : ''}
                                    `}
                          >
                            <Box style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '8px' }}>
                              <Avatar
                                src={nestedItem.metadata.userImageUrl}
                                alt={nestedItem.metadata.userName}
                                style={{ width: '40px', height: '40px' }}
                              />
                              <Box>
                                <Typography noWrap style={{ maxWidth: '120px', fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
                                  {nestedItem.metadata.userName}
                                </Typography>
                                <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '12px', color: '#475569' }}>
                                  {lastActivityDateFormat(nestedItem.createdAt)}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#1E293B' }}>
                              {nestedItem.text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      {item.status === 'OPEN' && ((showViewForEditor(subscription) && campaign.createdBy === profile?.user?.uuid) || showViewForReviewer(subscription) || showViewForAdmin(subscription)) && (
                        <Box className={`${classes.commentMessageInput} animate__animated animate__fadeIn animate__faster`}>
                          <TextField
                            value={commentMessage}
                            placeholder={'Reply...'}
                            autoFocus
                            onChange={(event) => {
                              setCommentMessage(event.target.value.substring(0, 300))
                            }}
                            onKeyPress={(event) => {
                              if (event.code.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') {
                                handleReplyForComment()
                              }
                            }}
                          />
                          <IconButton
                            size="small"
                            className={classes.commentMessageSendIcon}
                            onClick={() => handleReplyForComment()}
                          >
                            <SendMessageIcon />
                          </IconButton>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              : <Box
                  key={index}
                  className={`${classes.commentItem} ${item.id === currentComment?.id ? classes.commentItemActive : ''}`}
                >
                  <Box style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '8px' }}>
                    <Avatar
                      src={profile?.user?.profilePhoto}
                      alt={profile?.user?.name}
                      style={{ width: '40px', height: '40px' }}
                    />
                    <Box>
                      <Typography style={{ fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
                        {profile?.user?.name}
                      </Typography>
                    </Box>
                  </Box>
                  {((showViewForEditor(subscription) && campaign.createdBy === profile?.user?.uuid) || showViewForReviewer(subscription) || showViewForAdmin(subscription)) && (
                    <Box className={`${classes.commentMessageInput} animate__animated animate__fadeIn animate__faster`}>
                      <TextField
                        value={commentMessage}
                        placeholder={'Comment...'}
                        autoFocus
                        onChange={(event) => {
                          setCommentMessage(event.target.value.substring(0, 300))
                        }}
                        onKeyPress={(event) => {
                          if (event.code.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') {
                            handleSendComment()
                          }
                        }}
                      />
                      <IconButton
                        size="small"
                        className={classes.commentMessageSendIcon}
                        onClick={() => handleSendComment()}
                      >
                        <SendMessageIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
            ))
        : <Typography style={{ textAlign: 'center', fontSize: '12px', fontWeight: '500', lineHeight: '18px', color: '#475569' }}>
            No comments
          </Typography>
      }
    </>
  );
};

export default CommentsList;