import { api, apiCall, createApiCall } from "../api";
import { setIndustries, setJobRoles, setTeamSizes } from "../features/common";
import { store } from "../store";
import { ApiResponse } from "../types";
import {
  CompanySizeResponse,
  FileTypeResponse,
  IndustryResponse,
  RoleResponse
} from "../../interfaces/common";

const commonAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getFileTypesData: builder.query<FileTypeResponse[], { file_type: string }>({
      query: (params) => ({
        url: "/file_types",
        method: "GET",
        params
      })
    }),
    getJobRoles: builder.query<RoleResponse[], void>({
      query: () => ({
        url: "/roles",
        method: "GET"
      })
    }),
    getIndustries: builder.query<IndustryResponse[], void>({
      query: () => ({
        url: "/industries",
        method: "GET"
      })
    }),
    getTeamSize: builder.query<CompanySizeResponse[], void>({
      query: () => ({
        url: "/company_sizes",
        method: "GET"
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getFileTypesData = createApiCall<FileTypeResponse[], { file_type: string }>(commonAPI.endpoints.getFileTypesData);
export const getJobRoles = createApiCall<RoleResponse[], void>(commonAPI.endpoints.getJobRoles, { slicer: 'common', key: 'jobRoles', setFunction: setJobRoles });
export const getIndustries = createApiCall<IndustryResponse[], void>(commonAPI.endpoints.getIndustries, { slicer: 'common', key: 'industries', setFunction: setIndustries });
export const getTeamSize = createApiCall<CompanySizeResponse[], void>(commonAPI.endpoints.getTeamSize, { slicer: 'common', key: 'teamSizes', setFunction: setTeamSizes });
