import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: '700px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '32px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  title: {
    width: '520px',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33px',
    textAlign: 'center',
  },
  description: {
    width: '590px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '22px',
    textAlign: 'center',
    color: '#475569',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '8px',
    marginTop: '16px',
  },
  rejectButton: {
    width: '100%',
    border: '1px solid #CBD5E1',
    color: '#000000',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
  },
  confirmButton: {
    width: '100%',
    border: '1px solid #DC2626',
    color: '#DC2626',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
  },
}));
