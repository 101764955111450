import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  message: {
    fontFamily: 'Objectivity',
    width: '100%',
    padding: '2px 0px', 
    fontSize: '14px', 
    fontWeight: 500, 
    lineHeight: '21px',
    overflowX: 'hidden',
    overflowY: 'auto',
    whiteSpace: 'break-spaces',
    border: 'none',
  },
  tailoredReply: {
    // paddingRight: '40px',
    padding: '10px 12px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    background: '#FFFFFF',
    fontSize: '14px', 
    fontWeight: 500, 
    lineHeight: '21px', 
    color: '#090A14',
    '& *': {
      margin: 0,
    },
    '& ul': {
      whiteSpace: 'initial',
    },
    '& ol': {
      whiteSpace: 'initial',
    },
  },
  editorWrapper: {

  },
  editorToolbar: {
    display: 'none !important'
  },
  editorTextarea: {
    padding: '10px 12px',
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    background: '#FFFFFF',
    fontSize: '14px', 
    fontWeight: 500, 
    lineHeight: '21px', 
    color: '#090A14',
    '& .DraftEditor-root': {
      '& .DraftEditor-editorContainer': {
        '& .public-DraftStyleDefault-block': {
          margin: '0px !important'
        },
        '& .public-DraftEditor-content': {
          margin: '0px !important',
          '& h1': {
            margin: '0px !important',
          },
          '& h2': {
            margin: '0px !important',
          },
          '& h3': {
            margin: '0px !important',
          },
          '& h4': {
            margin: '0px !important',
          },
          '& h5': {
            margin: '0px !important',
          },
          '& h6': {
            margin: '0px !important',
          }
        }
      }
    }
  }
}));
