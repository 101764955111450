import React, { useEffect, useState } from 'react';
import { Modal, Box, IconButton, Typography, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as CloseRedIcon } from '../../../../assets/icons/close_red.svg';
import { ReactComponent as EditOrangeIcon } from '../../../../assets/icons/edit_orange.svg';
import { ReactComponent as CheckmarkGreenIcon } from '../../../../assets/icons/checkmark_green.svg';
import RadioButton from '../../../../components/main/CustomFields/RadioButton';
import { REVIEW_STATUS } from '../../../../utils/enums';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (status: string) => void;
}

const STATUS_OPTIONS = [
  {
    status: REVIEW_STATUS.APPROVED,
    title: 'I approve',
    description: 'Grants your team full platform access and initiates training for your dedicated AI agent.',
    activeColor: '#D1FAE566',
    activeIcon: <CheckmarkGreenIcon />
  },
  {
    status: REVIEW_STATUS.CHANGE_REQUIRED,
    title: 'Changes needed',
    description: 'Not approving indicates to the team that additional changes are necessary.',
    activeColor: '#FEF3C766',
    activeIcon: <EditOrangeIcon />
  },
  {
    status: REVIEW_STATUS.REJECTED,
    title: 'Reject Review',
    description: 'Reject approving indicates that the work should be done from scratch.',
    activeColor: '#FEE2E266',
    activeIcon: <CloseRedIcon />
  },
]

const ChooseReviewStatusModal: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onSave,
  } = props;
  const [status, setStatus] = useState<string | null>(null);

  const classes = useStyles();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSave?.(status);
      } else if (event.key === 'Escape' || event.key === 'Backspace' || event.key === 'Delete') {
        onClose?.();
      }
    };

    if (open && status) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onClose, onSave, status]);

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title}>
          Do you approve the generated intel?
        </Typography>

        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          {STATUS_OPTIONS.map((item, index) => (
            <Box
              key={index}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '8px',
                background: item.status === status ? item.activeColor : 'transparent',
                padding: '8px 20px 8px 8px'
              }}
            >
              <RadioButton
                checked={item.status === status}
                label={
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                    <Typography style={{ fontSize: '12px', fontWeight: '600', lineHeight: '22px' }}>
                      {item.title}
                    </Typography>
                    <Typography style={{ width: '400px', fontSize: '12px', fontWeight: '500', lineHeight: '17px', color: '#475569' }}>
                      {item.description}
                    </Typography>
                  </Box>
                }
                onChange={() => setStatus(item.status)}
              />
              <Box>
                {item.status === status
                  ? item.activeIcon
                  : null
                }
              </Box>
            </Box>
          ))}
        </Box>

        <Button
          fullWidth
          variant='contained'
          color='primary'
          disabled={!status}
          onClick={() => onSave(status)}
        >
          Save
        </Button>
      </Box>
    </Modal>
  );
};

export default ChooseReviewStatusModal;
