import React, { useState } from "react";
import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import ConfirmationModal from "../../../../components/main/ConfirmationModal";
import { deleteChat } from "../../../../redux/services/copilot";
import { notify } from "../../../../providers/notification";
import { Chat, CopilotTypes } from "../../../../interfaces/copilot";

import { useStyles } from "./styles";

interface Props {
  open: boolean;
  tab: CopilotTypes;
  chats: Chat[];
  chat: Chat;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  isOnboardingCompleted: boolean;
  isSubscriptionActive: boolean;
  handleGetChats: (isAddNewChat: boolean) => void;
  setSideBarOpen: (open: boolean) => void;
  setChat: (chat: Chat) => void;
}

const SideBar: React.FC<Props> = ({
  open,
  tab,
  chats,
  chat,
  loading,
  setLoading,
  isOnboardingCompleted,
  isSubscriptionActive,
  handleGetChats,
  setSideBarOpen,
  setChat,
}) => {
  const [deleteConfig, setDeleteConfig] = useState<{ active: boolean, targetId: number | string | null }>({
    active: false,
    targetId: null
  });

  const history = useHistory();
  const classes = useStyles();

  const handleDeleteChat = async (id) => {
    setLoading(true);
    setDeleteConfig({
      active: false,
      targetId: null
    });

    const { error } = await deleteChat({ id, tab: tab.toLowerCase() });

    if (!error) {
      if (chat?.id === id) {
        history.push(`/copilot/${tab}`);
      } else {
        handleGetChats(false);
      }

      notify.success('Chat deleted successfully');
    } else {
      notify.error('Failed to delete the chat');
    }

    setLoading(false);
  }

  return (
    <>
      <Box className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}>
        <Box className={classes.sideBarCloseButton}>
          <IconButton onClick={() => setSideBarOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          className={classes.sideBarContent}
          // style={{
          //   opacity: (isOnboardingCompleted && isSubscriptionActive) ? '1' : '0.5',
          //   pointerEvents: (isOnboardingCompleted && isSubscriptionActive) ? 'all' : 'none',
          // }}
        >
          {open && (
            loading
              ? <CircularProgress
                  color="inherit"
                  style={{ margin: '60px auto' }}
                />
              : chats?.map((item, index) => (
                  <Box
                    key={index + item.id}
                    className={`${classes.sideBarItem} ${(chat?.id === item.id || item.id === 'none') ? classes.sideBarItemActive : ''}`}
                    style={{
                      opacity: !item.id || !isOnboardingCompleted || !isSubscriptionActive
                        ? '0.5' 
                        : '1',
                      pointerEvents: !item.id || !isOnboardingCompleted || !isSubscriptionActive
                        ? 'none'
                        : 'all'
                    }}
                    onClick={() => {
                      if (item.id && item.id !== 'none') {
                        history.push(`/copilot/${tab}/${item.id}`);
                      }
                    }}
                  >
                    <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
                      {item.title}
                    </Typography>
                    {item.id !== 'none' && (
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                        
                          setDeleteConfig({
                            active: true,
                            targetId: item.id
                          })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                ))
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          disabled={!isOnboardingCompleted || !isSubscriptionActive}
          onClick={() => {
            if (chat && chat?.id !== 'none' && isOnboardingCompleted && isSubscriptionActive) {
              setChat(null);
              history.push(`/copilot/${tab}`);
            }
          }}
          startIcon={<>+</>}
        >
          New chat
        </Button>
      </Box>

      <ConfirmationModal
        open={deleteConfig.active}
        title={'Are you sure you want to delete the chat?'}
        description={'Please be aware that the entire conversation with Pebbles will be permanently deleted and cannot be recovered.'}
        confirmBtnText={'Delete'}
        rejectBtnText={'Cancel'}
        onClose={() => setDeleteConfig({
          active: false,
          targetId: null
        })}
        onConfirm={() => handleDeleteChat(deleteConfig.targetId)}
        onReject={() => setDeleteConfig({
          active: false,
          targetId: null
        })}
      />
    </>
  );
};

export default SideBar;
