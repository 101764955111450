import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto',
    gap: '28px',
    padding: '40px 30px'
  },
  header: {
    width: '100%',
    minWidth: '880px',
    // maxWidth: '1080px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    margin: '0 auto'
  },
  selection: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '0px 16px'
  },
  selectionLeftBlock: {
    width: '48%',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  selectionRightBlock: {
    width: '48%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '7px'
  },
  content: {

  },
  notFound: {
    margin: '80px auto',
    textAlign: 'center',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0px)'
  }
}));