import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { getReviewLogCampaigns } from '../../redux/services/my-library';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import LogTableRow from './components/LogTableRow';
import Filters from './components/Filters';
import { getOnboardingCompleteness } from '../../redux/services/onboarding';
import { getCurrentSubscription } from '../../redux/services/subscriptions';
import { getCurrentSubscriptionStatus } from '../../helpers/subscription';
import { showViewForEditor } from '../../helpers/roles';
import { ReactComponent as SortArrowDownIcon } from '../../assets/icons/arrow_down_filled.svg';
import { ReactComponent as SortArrowUpIcon } from '../../assets/icons/arrow_up_filled.svg';
import { REVIEW_STATUS } from '../../utils/enums';
import Dropdown from '../../components/main/CustomFields/Dropdown';
import { ReactComponent as SortIcon } from '../../assets/icons/sort_icon.svg';

import { useStyles } from './styles';

const ReviewLog = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterTerm, setFilterTerm] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [subscription, setSubscription] = useState<any>(null);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState<boolean>(false);

  const classes = useStyles();
  const options = [
    { value: REVIEW_STATUS.PENDING_REVIEW, label: 'Pending review' },
    { value: REVIEW_STATUS.APPROVED, label: 'Approved' },
    { value: REVIEW_STATUS.CHANGE_REQUIRED, label: 'Changes required' },
    { value: REVIEW_STATUS.REJECTED, label: 'Rejected' },
  ]

  useEffect(() => {
    setLoading(true);

    handleIsSubscriptionActive();
    handleIsOnboardingCompleted();
  }, []);

  useEffect(() => {
    setSortOrder('desc');
  }, [filterTerm]);

  useEffect(() => {
    if (subscription) {
      handleGetReviewLogCampaigns();
    }
  }, [sortOrder, filterTerm, subscription]);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    setSubscription(data);

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  }

  const handleIsOnboardingCompleted = async () => {
    const { data } = await getOnboardingCompleteness();

    if (data && Object.values(data).every(item => item)) {
      setIsOnboardingCompleted(true);
    }
  }

  const handleGetReviewLogCampaigns = async () => {
    const getFor = showViewForEditor(subscription)
      ? 'user'
      : 'account';

    const { data, error } = await getReviewLogCampaigns({
      type: getFor,
      reviewStatuses: filterTerm.length
        ? filterTerm
        : [REVIEW_STATUS.APPROVED, REVIEW_STATUS.PENDING_REVIEW, REVIEW_STATUS.CHANGE_REQUIRED, REVIEW_STATUS.REJECTED]
    });

    if (data && !error) {
      const newCampaigns = [...data].sort((a, b) => {
        if (sortOrder === 'asc') {
          return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
        } else {
          return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        }
      })

      setCampaigns(newCampaigns);
    }

    setLoading(false);
  };

  const handleSearchChange = (event) => {
    if (!isOnboardingCompleted || !isSubscriptionActive) {
      setShowActionBlockedModal(true);
    } else {
      const { value } = event.target;

      setSearchTerm(value);
      debouncedSearch(value);
    }
  };

  const debouncedSearch = useMemo(() => _.debounce((query) => {
    if (query) {
      const filtered = campaigns.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.description?.toLowerCase()?.includes(query.toLowerCase())
      );
      setCampaigns(filtered);
    } else {
      handleGetReviewLogCampaigns();
    }
  }, 300), [campaigns]);
  
  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 99999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '48px',
              fontWeight: '600',
              lineHeight: '58px',
              color: '#000000'
            }}
          >
            Review log
          </Typography>
        </Box>

        <Filters
          filterTerm={filterTerm}
          searchTerm={searchTerm}
          isOnboardingCompleted={isOnboardingCompleted}
          isSubscriptionActive={isSubscriptionActive}
          setFilterTerm={setFilterTerm}
          handleSearchChange={handleSearchChange}
        />

        <Box className={classes.content}>
          <TableContainer
            component={Paper}
            style={{
              width: '100%',
              minWidth: '1070px',
              // maxWidth: '1080px',
              boxShadow: 'none',
              background: 'transparent',
              margin: '0 auto',
              // opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
              // pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all'
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      // width: '25%',
                      borderColor: 'transparent',
                      fontWeight: '400',
                      // color: '#475569'
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      // width: '55%',
                      borderColor: 'transparent',
                      fontWeight: '400',
                      // color: '#475569'
                    }}
                  >
                    Author
                  </TableCell>
                  <TableCell
                    align="left"
                    sortDirection={sortOrder}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      borderColor: 'transparent',
                      // color: '#475569',
                      cursor: 'pointer',
                      fontWeight: '400',
                    }}
                    onClick={() => setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')}
                  >
                    Last update &nbsp;
                    {sortOrder === 'desc'
                      ? <SortArrowDownIcon style={{ color: '#475569' }} />
                      : <SortArrowUpIcon style={{ color: '#475569' }} />
                    }
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      // width: '10%',
                      borderColor: 'transparent',
                      // color: '#475569'
                    }}
                  >
                    <Dropdown
                      value={filterTerm.length 
                        ? filterTerm.map(item => {
                            return options.find(opt => opt.value === item).label
                          })
                        : ['Status']
                      }
                      options={options}
                      placeholder={'Status'}
                      type={'text'}
                      optionsType={'checkboxes'}
                      arrowIcon={SortIcon}
                      hideSelectedValues={true}
                      style={{
                        width: '105px',
                      }}
                      onChange={(event, value) => {
                        let newValue = [];
                    
                        value
                          .filter(item => item !== 'Status')
                          .forEach(item => {
                            newValue.push(
                              options.find(opt => opt.label === item).value
                            )
                          })
                    
                        setFilterTerm(newValue)
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      // width: '10%',
                      borderColor: 'transparent',
                      // color: '#475569'
                    }}
                  />
                </TableRow>
              </TableHead>
              {campaigns && campaigns.length > 0
                ? <TableBody
                    style={{
                      position: 'relative'
                    }}
                  >
                    {campaigns.map((item, index) => (
                      <React.Fragment key={index}>
                        <LogTableRow
                          item={item}
                        />
                      </React.Fragment>
                    ))}
                  </TableBody>
                : <Box className={classes.notFound}>
                    No campaigns
                  </Box>
              }
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <ConfirmationModal
        open={showActionBlockedModal}
        title={
          !isSubscriptionActive
            ? 'To proceed, you have to be subscribed'
            : 'To proceed, you have to complete the Onboarding'
        }
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={
          !isSubscriptionActive
            ? 'Subscribe later'
            : 'Complete later'
        }
        rejectBtnText={
          !isSubscriptionActive
            ? 'Go to subscriptions'
            : 'Go to onboarding'
        }
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() =>
          !isSubscriptionActive
            ? props.history.push('/onboarding')
            : props.history.push('/subscriptions')
        }
      />
    </>
  );
};

export default ReviewLog;
