import { api, apiCall, createApiCall } from "../api";
import {
  AuthRequest,
  AuthResponse,
  CompleteRegistration,
  PasswordRecovery
} from "../../interfaces/authentication";

const authenticationAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.query<AuthResponse, AuthRequest>({
      query: (body) => ({
        url: `/auth/login`,
        method: "POST",
        body
      })
    }),
    signUpUser: builder.query<{ id: string }, { email: string }>({
      query: (body) => ({
        url: `/accounts/register-email`,
        method: "POST",
        body
      })
    }),
    sendResetPasswordLink: builder.query<void, { email: string }>({
      query: (body) => ({
        url: `/auth/password-recovery`,
        method: "POST",
        body
      })
    }),
    saveNewPassword: builder.query<void, { token: string, body: PasswordRecovery }>({
      query: ({ token, body }) => ({
        url: `/auth/password-recovery/${token}`,
        method: "PUT",
        body
      })
    }),
    confirmOtpCode: builder.query<void, { email: string, otp: string }>({
      query: (body) => ({
        url: `/accounts/confirm-email`,
        method: "POST",
        body
      })
    }),
    updateAccountDetails: builder.query<AuthResponse, CompleteRegistration>({
      query: (body) => ({
        url: `/accounts/complete-registration`,
        method: "POST",
        body
      })
    }),
    updateAccountDetails2: builder.query<AuthResponse, { body: CompleteRegistration, token: string }>({
      query: ({ body, token }) => ({
        url: `/accounts/accept/${token}`,
        method: "POST",
        body
      })
    }),
    getReferralCode: builder.query<{ code: string }, void>({
      query: () => ({
        url: `/v1/license/referral-code`,
        method: "GET"
      })
    }),
    setReferralCodeAccount: builder.query<void, { accountId: string, referralCode: string }>({
      query: (body) => ({
        url: `/v1/license/referrals`,
        method: "PUT",
        body
      })
    }),
    getReferralsAmount: builder.query<{ amount: number }, void>({
      query: () => ({
        url: `/v1/license/referrals/amount`,
        method: "GET"
      })
    })
  }),
  overrideExisting: true
});

export const loginUser = createApiCall<AuthResponse, AuthRequest>(authenticationAPI.endpoints.loginUser);
export const signUpUser = createApiCall<{ id: string }, { email: string }>(authenticationAPI.endpoints.signUpUser);
export const sendResetPasswordLink = createApiCall<void, { email: string }>(authenticationAPI.endpoints.sendResetPasswordLink);
export const saveNewPassword = createApiCall<void, { token: string, body: PasswordRecovery }>(authenticationAPI.endpoints.saveNewPassword);
export const confirmOtpCode = createApiCall<void, { email: string, otp: string }>(authenticationAPI.endpoints.confirmOtpCode);
export const updateAccountDetails = createApiCall<AuthResponse, CompleteRegistration>(authenticationAPI.endpoints.updateAccountDetails);
export const updateAccountDetails2 = createApiCall<AuthResponse, { body: CompleteRegistration, token: string }>(authenticationAPI.endpoints.updateAccountDetails2);
export const getReferralCode = createApiCall<{ code: string }, void>(authenticationAPI.endpoints.getReferralCode);
export const setReferralCodeAccount = createApiCall<void, { accountId: string, referralCode: string }>(authenticationAPI.endpoints.setReferralCodeAccount);
export const getReferralsAmount = createApiCall<{ amount: number }, void>(authenticationAPI.endpoints.getReferralsAmount);