import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
    padding: '16px 50px 16px 16px',
    background: '#DDEFFF',
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: '1px solid rgb(203, 213, 225)',
  },
  message: {
    maxWidth: '249px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
    color: '#000000'
  },
}));
