import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  filters: {
    width: '100%',
    minWidth: '880px',
    // maxWidth: '1080px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
    margin: '0 auto'
  },
  filtersLeftBlock: {
    width: '48%',
    display: 'flex',
    alignItems: 'center',
  },
  tabsBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px',
    border: '1px solid #CBD5E1',
    borderRadius: '80px',
    gap: '5px'
  },
  tabsItem: {
    height: '34px',
    borderRadius: '80px',
    padding: '6px 16px',
    cursor: 'pointer',
    transition: 'all .3s',
    fontSize: '12px',
    lineHeight: '22px',
    fontWeight: 600,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.1)',
      // color: 'white'
    }
  },
  tabsItemActive: {
    background: 'black !important',
    color: 'white !important'
  },
  filtersRightBlock: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px'
  },
}));
