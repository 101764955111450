import React from "react";
import { Message } from "../../../framework/main/Message";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountDetailController, { Props } from "./AccountDetailController";
import { ErrorMessage, Formik } from "formik";
import { CompanySchema } from "../utils/schema";
import { styles } from "../utils/styles";
import Layout from "./Layout.web";
import { locationIcon, searchIcon } from "./assets";
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { meunuProps } from "./About.web";


const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: { name: string }) => option.name,
});


export type CompanyValues = {
  describes: string;
  location: string;
  website: string;
};

const initialValues: CompanyValues = { describes: "", location: "", website: "" };

export class CompanyDetail extends AccountDetailController {
  constructor(props: Props) {
    super(props);
  }


  onCompanyBack = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      // 'LetsGetStarted'
      'SignUp'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Box className={classes.layoutContainer} style={{ marginTop: '90px' }}>
          <Box className={classes.viewBox}>
            <Typography className={classes.createAccount}>
              Describe your company
            </Typography>
            <Typography className={classes.signingAs}>
              Could you also provide some details about your company?
            </Typography>

            <Formik
              data-testid="companyDetailsFormik"
              initialValues={initialValues}
              validationSchema={CompanySchema}
              onSubmit={values => {
                this.createCompanyAccount(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit} data-test-id="AccountDetails">
                  <Box className={classes.inputWrapper}>
                    <Typography className={classes.title}>
                      What best describes your company?
                    </Typography>
                    <Select
                      fullWidth
                      name="describes"
                      value={values.describes}
                      onChange={handleChange}
                      displayEmpty
                      disableUnderline
                      inputProps={{ 'aria-label': 'company-size' }}
                      MenuProps={meunuProps}
                      className={`${classes.select} ${values.describes ? '' : classes.inactiveSelect1}`}
                      data-test-id="seldesCompany"
                      style={{
                        borderColor: touched.describes && errors.describes ? "#F87171" : ""
                      }} >
                      {this.state.companyDes.map((detail) => (
                        <MenuItem className={classes.setmenuItem} value={detail.id}>{detail.name}</MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="describes" component="div" className={classes.errorResp} />
                  </Box>
                  <Box className={classes.inputWrapper}>
                    <Typography className={classes.title}>
                      In what country is the main office situated?
                    </Typography>
                    <div className={classes.locationSearch}>
                      <Autocomplete
                        options={this.state.locationList}
                        getOptionLabel={(option: any) => option.name || ''}
                        data-testid='locationInputs'
                        filterOptions={filterOptions}
                        onChange={(e: any, newVal: any) => {
                          setFieldValue('location', newVal?.name || '')
                        }}
                        className={classes.autocomplete}
                        classes={{
                          option: classes.autocompleteOption,
                          listbox: classes.autocompleteListBox
                        }}
                        id='location'
                        renderInput={(params: any) => <TextField  {...params}
                          onBlur={() => { }}
                          style={{
                            borderColor: touched.location && errors.location ? "#F87171" : ""
                          }}
                          placeholder="Search location"
                          className={`${classes.inputAutoComplete}`}
                          variant="outlined" />}
                      />
                      <img src={locationIcon} className={classes.locationIcons} />
                      <img src={searchIcon} className={classes.searchIcons} />
                      <Typography id="accountId-error" className={classes.validateError}>
                        {errors.location && touched.location ? errors.location : ""}
                      </Typography>
                    </div>
                  </Box>
                  <Box className={classes.inputWrapper}>
                    <Typography className={classes.title}>What is your company website?</Typography>
                    <TextField
                      data-test-id="txtInputWebsiteCompanyName"
                      name="website"
                      fullWidth
                      id="websites"
                      variant="outlined"
                      value={values.website}
                      placeholder="Insert link here"
                      onChange={handleChange}
                      className={classes.input1}
                      style={{
                        borderColor: touched.website && errors.website ? "#F87171" : ""
                      }}
                    />
                    <ErrorMessage name="website" component="div" className={classes.errorResp} />
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    className={classes.buttonWrapper}
                  >
                    Continue
                  </Button>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(CompanyDetail);
