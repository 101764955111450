import React from 'react';
import { Box, Typography, CircularProgress as Progress } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  value: number;
  totalValue: number;
  additionalText?: any;
  showInnerContent?: boolean;
  colors?: {
    primary: string,
    secondary: string,
    background?: string,
    value?: string,
    totalValue?: string,
    additionalText?: string,
  },
  sizes?: {
    width: string,
    height: string,
  },
  strokeWidth?: string,
  allowOverlap?: boolean,
}

const CircularProgress: React.FC<Props> = (props) => {
  const {
    value = 0,
    totalValue = 0,
    additionalText,
    showInnerContent = true,
    colors = {
      primary: '#FFE4A9',
      secondary: '#FFF8DC66',
      background: '#FFFFFF',
      value: '#000000',
      totalValue: '#475569',
      additionalText: '#475569',
    },
    sizes = {
      width: '135px',
      height: '135px',
    },
    strokeWidth = '2px',
    allowOverlap = false,
  } = props;

  const classes = useStyles({ colors, sizes, strokeWidth });

  return (
    <Box className={classes.cardProgress}>
      <Progress
        variant="determinate"
        value={
          Math.abs(
            allowOverlap
              ? value >= totalValue 
                ? (value % totalValue / totalValue) * 100 || 100
                : (value / totalValue) * 100
              : value >= totalValue 
                ? 100
                : (value / totalValue) * 100
          )
        }
      />
      <Box className={classes.innerContent}>
        {showInnerContent && (
          <>
            <Typography style={{ display: 'flex', alignItems: 'flex-end', fontSize: '28px', fontWeight: '400', lineHeight: '33px', color: colors.value }}>
              {value}
              <Typography style={{ fontSize: '16px', lineHeight: '19px', color: colors.totalValue }}>
                /{totalValue}
              </Typography>
            </Typography>
            {additionalText && (
              <Typography style={{ textAlign: 'center', fontSize: '10px', fontWeight: '500', lineHeight: '11px', color: colors.additionalText }}>
                {additionalText}
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CircularProgress;
