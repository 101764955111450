import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, FormHelperText, IconButton, Typography } from '@material-ui/core';
import TextField from '../../../../components/main/CustomFields/TextField';
import { ReactComponent as EditDarkIcon } from '../../../../assets/icons/edit_dark.svg';
import { ReactComponent as TotalFilledCheckmarkIcon } from '../../../../assets/icons/green_checkmark.svg';
import { ReactComponent as PartialFilledCheckmarkIcon } from '../../../../assets/icons/green_checkmark_2.svg';
import LimitationWidget from '../../../../components/main/LimitationWidget';
import { getUrlAndParams } from '../../../../helpers/other';
import { CampaignModelResponse, CampaignResponse, CampaignCategories, CampaignSubCategory, TemplateAttributeTypes } from '../../../../interfaces/campaign';

import { useStyles } from './styles';

interface Props {
  values: any;
  template: CampaignResponse | CampaignModelResponse;
  errors: any;
  touched: any;
  templateMetadata: Record<string, { value: string; active: boolean }>;
  validationSchema: any;
  isAllowToEdit: boolean;
  setTemplateMetadata: (data: any) => void;
  getCompletedFields: (startName: string | number, values: any, key?: string) => { all: any, required: any };
}

const Header: React.FC<Props> = ({
  values,
  template,
  errors,
  touched,
  templateMetadata,
  validationSchema,
  isAllowToEdit,
  setTemplateMetadata,
  getCompletedFields,
}) => {
  const [showNameAndDescriptionBlock, setShowNameAndDescriptionBlock] = useState<boolean>(false);

  const classes = useStyles();
  // const separatedFieldsKeys = template.sections.map(item => {
  //   return item.attributes.filter(item => item.type === TemplateAttributeTypes.SEPARATED_QUESTION_ATTRIBUTE).map(item => item.key)
  // }).flat();
  const isMultiTouch = template.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS;

  useEffect(() => {
    const { params } = getUrlAndParams();
    const target_email_id = params.get('target_email_id');

    setShowNameAndDescriptionBlock(!target_email_id);
  }, []);

  const getProgressBars = () => {
    let result = {};

    template?.sections?.forEach(section => {
      if (section.subSections.length) {
        const { required } = getCompletedFields(section.id, values);

        result = {
          ...result,
          ...required.fields
        }
      } else {
        const sections = section.attributes.filter(item => item.type === TemplateAttributeTypes.SEPARATED_QUESTION_ATTRIBUTE)

        sections.forEach(item => {
          const { required } = getCompletedFields(section.id, values, item.key);

          result = {
            ...result,
            [item.key]: required.fields
          }
        })
      }
    });

    return Object.values(result);
  }

  const handleTemplateMetadata = (field, value, active = true) => {
    setTemplateMetadata({
      ...templateMetadata,
      [field]: {
        ...templateMetadata[field],
        value,
        active
      }
    })
  }

  const getTemplateDefaultName = () => {
    return template.category === CampaignCategories.MARKETING
      ? 'Marketing template'
      : template.category === CampaignCategories.SALES
        ? 'Sales template'
        : 'Product template'
  }

  // console.log('----------------');
  // console.log('validationSchema', validationSchema);
  // console.log('values', values);
  // console.log('errors', errors);
  // console.log('touched', touched);
  // console.log('----------------');

  return (
    <>
      <Box className={classes.header}>
        <Box className={classes.headerBreadCrumbs}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography style={{ fontSize: '20px', fontWeight: '600', lineHeight: '30px' }}>
              {getTemplateDefaultName()}
            </Typography>
            <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
              /
            </Typography>
            <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
              {template?.name}
            </Typography>
          </Box>
          <LimitationWidget
            type='TACTICAL_OUTREACH'
          />
        </Box>
        {!!getProgressBars().length && (
          <Box className={classes.headerProgressBar}>
            <Box className={classes.headerProgressBarLeftBox}>
              {getProgressBars().every(item =>
                typeof item === 'object'
                  ? Object.values(item).every(item => item)
                  : item
              )
                ? <TotalFilledCheckmarkIcon />
                : getProgressBars().some(item =>
                  typeof item === 'object'
                    ? Object.values(item).some(item => item)
                    : item
                )
                  ? <PartialFilledCheckmarkIcon />
                  : null
              }

              <Box style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <Typography style={{ fontSize: '14px', fontWeight: '600', lineHeight: '14px', color: '#475569' }}>
                  {getProgressBars().every(item =>
                    typeof item === 'object'
                      ? Object.values(item).every(item => item)
                      : item
                  )
                    ? 'Solid strategy!'
                    : getProgressBars().some(item =>
                      typeof item === 'object'
                        ? Object.values(item).some(item => item)
                        : item
                    )
                      ? 'Great start!'
                      : `Let's get started!`
                  }
                </Typography>
                <Typography style={{ fontSize: '10px', fontWeight: '500', lineHeight: '12px', color: '#475569' }}>
                  {getProgressBars().every(item =>
                    typeof item === 'object'
                      ? Object.values(item).every(item => item)
                      : item
                  )
                    ? `Let's begin the creation process.`
                    : getProgressBars().some(item =>
                      typeof item === 'object'
                        ? Object.values(item).some(item => item)
                        : item
                    )
                      ? isMultiTouch
                        ? 'Complete all parameters to maximise campaign efficacy.'
                        : 'Complete all parameters to maximise email efficacy.'
                      : `First, let's understand what you're trying to achieve.`
                  }
                </Typography>
              </Box>
            </Box>
            <Box className={classes.headerProgressBarRightBox}>
              {getProgressBars().map((item, index) => (
                typeof item === 'object'
                  ? Object.values(item).map(item => (
                    <Box
                      key={index}
                      style={{
                        width: '100%',
                        minWidth: '20px',
                        maxWidth: '100%',
                        height: '4px',
                        border: `1px solid ${item ? '#059669' : '#475569'}`,
                        background: item ? '#059669' : 'transparent',
                        borderRadius: '8px'
                      }}
                    />
                  ))
                  : <Box
                    key={index}
                    style={{
                      width: '100%',
                      minWidth: '20px',
                      maxWidth: '100%',
                      height: '4px',
                      border: `1px solid ${item ? '#059669' : '#475569'}`,
                      background: item ? '#059669' : 'transparent',
                      borderRadius: '8px'
                    }}
                  />
              ))}
            </Box>
          </Box>
        )}
      </Box>
      {showNameAndDescriptionBlock && (
        <Box className={classes.templateNameAndDescription}>
          {templateMetadata.name.active
            ? <Box className={classes.templateName}>
              <TextField
                value={templateMetadata.name.value}
                placeholder={'Name your campaign'}
                autoFocus
                error={templateMetadata.name.value.trim().length > 50 ? 'Max 50 characters' : ''}
                onChange={(event) => handleTemplateMetadata('name', event.target.value)}
                onBlur={() => handleTemplateMetadata('name', templateMetadata.name.value.trim().length ? templateMetadata.name.value.trim() : `${template.name} ${moment().format('DD-MMM-YYYY HH:mm')}`, false)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();

                    handleTemplateMetadata('name', templateMetadata.name.value.trim().length ? templateMetadata.name.value.trim() : `${template.name} ${moment().format('DD-MMM-YYYY HH:mm')}`, false)
                  }
                }}
              />
            </Box>
            : <Box className={classes.templateName}>
              <Typography
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '38px', fontWeight: '600', lineHeight: '46px', color: '#000000' }}
                onClick={() => {
                  if (isAllowToEdit) {
                    handleTemplateMetadata('name', templateMetadata.name.value)
                  }
                }}
              >
                {templateMetadata.name.value}
                {isAllowToEdit && (
                  <IconButton>
                    <EditDarkIcon />
                  </IconButton>
                )}
              </Typography>
              {templateMetadata.name.value.trim().length > 50 && (
                <FormHelperText error>
                  Max 50 characters
                </FormHelperText>
              )}
            </Box>
          }
          {templateMetadata.description.active
            ? <Box className={classes.templateDescription}>
              <TextField
                value={templateMetadata.description.value}
                placeholder={'Describe your campaign'}
                autoFocus
                error={templateMetadata.description.value.trim().length > 300 ? 'Max 300 characters' : ''}
                onChange={(event) => handleTemplateMetadata('description', event.target.value)}
                onBlur={() => handleTemplateMetadata('description', templateMetadata.description.value.trim(), false)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();

                    handleTemplateMetadata('description', templateMetadata.description.value.trim(), false)
                  }
                }}
              />
            </Box>
            : <Box className={classes.templateDescription}>
              <Typography
                style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '18px', fontWeight: '500', lineHeight: '27px', color: '#475569' }}
                onClick={() => {
                  if (isAllowToEdit) {
                    handleTemplateMetadata('description', templateMetadata.description.value)
                  }
                }}
              >
                {templateMetadata.description.value || 'Edit Description'}
                {isAllowToEdit && (
                  <IconButton>
                    <EditDarkIcon />
                  </IconButton>
                )}
              </Typography>
              {templateMetadata.description.value.trim().length > 300 && (
                <FormHelperText error>
                  Max 300 characters
                </FormHelperText>
              )}
            </Box>
          }
        </Box>
      )}
    </>
  );
};

export default Header;
