import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: '0px',
    minWidth: '0px',
    maxWidth: '0px',
    height: '100vh',
    background: '#CBD5E1',
    borderLeft: '1px solid #969696',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    zIndex: 10,
    padding: '20px 0px',
    overflow: 'hidden',
    // transition: 'all .4s',
    position: 'relative'
  },
  sideBarOpen: {
    // width: '270px',
    minWidth: '270px',
    // maxWidth: '270px',
    padding: '20px 12px',
  },
  sideBarCloseButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  sideBarContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '5px 12px 5px 2px'
  },
  sideBarNavigationButtons: {
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: '#FFFFFF',
    border: '1px solid #E2E8F0',
    borderRadius: '7px',
  },
  sideBarNavigationButton: {
    width: '36px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    background: '#FFFFFF',
    cursor: 'pointer',
    '& svg': {
      '& path': {
        fill: '#475569'
      }
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    }
  },
  sideBarNavigationButtonActive: {
    width: '36px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    background: 'black',
    cursor: 'pointer',
    '& svg': {
      '& path': {
        fill: 'white'
      }
    },
    '&:hover': {
      background: 'black',
    }
  },
  resizeController: {
    position: 'absolute',
    height: '100%',
    width: '5px',
    top: 0,
    left: 0,
    cursor: 'col-resize',
    '&:hover': {
      borderLeft: '1px solid #1890ff'
    }
  }
}));
