import * as Yup from "yup";

export const maxWords = (max, message) => {
  return Yup.string().test('max-words', message, function (value) {
    const { path, createError } = this;
    if (!value) return true; // Skip validation if value is empty or undefined
    const wordCount = value.trim().split(/\s+/).length;

    return wordCount <= max || createError({ path, message });
  });
};