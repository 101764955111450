import React from 'react';
import Success from '../components/main/Notifications/Success';
import Error from '../components/main/Notifications/Error';
import Info from '../components/main/Notifications/Info';
import CloseButton from '../components/main/Notifications/CloseButton';
import { ToastContainer, toast, Slide } from 'react-toastify';

export const notify = {
  success: (message) => toast(<Success message={message} />),
  error: (message) => toast(<Error message={message} />),
  info: (message) => toast(<Info message={message} />),
};

export const NotificationProvider = ({ children }) => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        closeButton={<CloseButton />}
        transition={Slide}
        toastStyle={{
          position: 'relative',
          padding: '0px',
          background: 'transparent',
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
          margin: '0 0 5px 0',
          justifyContent: 'center'
        }}
      />
      {children}
    </>
  );
};
