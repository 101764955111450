import React, { useEffect } from 'react';
import { Modal, Box, IconButton, Typography, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  title: string | Element | React.ReactElement;
  description?: string;
  rejectBtnText?: string;
  confirmBtnText?: string;
  width?: string;
  reversedButtons?: boolean;
  onClose: () => void;
  onReject?: () => void;
  onConfirm?: () => void;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  const {
    open,
    title,
    description,
    rejectBtnText,
    confirmBtnText,
    width = '700px',
    reversedButtons = false,
    onClose,
    onReject,
    onConfirm,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onConfirm?.();
      } else if (event.key === 'Escape') {
        onClose();
      } else if (event.key === 'Backspace' || event.key === 'Delete') {
        onReject?.();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onConfirm, onClose, onReject]);

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      <Box className={classes.modalBox} style={{ maxWidth: width }}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title}>
          {title}
        </Typography>
        {description && (
          <Typography className={classes.description}>
            {description}
          </Typography>
        )}
        <Box 
          className={classes.buttonContainer}
          style={{
            flexDirection: reversedButtons ? 'row-reverse' : 'row',
          }}
        >
          {rejectBtnText && (
            <Button 
              onClick={onReject}
              variant="outlined"
              className={classes.rejectButton}
            >
              {rejectBtnText}
            </Button>
          )}
          {confirmBtnText && (
            <Button 
              onClick={onConfirm}
              variant="outlined"
              className={classes.confirmButton}
            >
              {confirmBtnText}
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
