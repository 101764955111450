import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import TextField from "../../../components/main/CustomFields/TextField";
import { getProfile } from "../../../redux/services/profile";
import StorageProvider from "../../../framework/main/StorageProvider";
import { getCurrentSubscription } from "../../../redux/services/subscriptions";
import { clearStorages } from "../../../helpers/other";
import { loginUser } from "../../../redux/services/authentication";
import { useDispatch } from "react-redux";
import { notify } from "../../../providers/notification";

import { useStyles } from "./styles";

const Login = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const handleSignUp = () => {
    history.push('/signup');
  }

  const handleForgotPassword = () => {
    history.push('/forgot-password');
  }

  const handleLogin = async (email: string, password: string) => {
    setLoading(true);

    const body = {
      email: email,
      password: password,
    };

    const { data, error } = await loginUser(body);

    if (data && !error) {
      await StorageProvider.setItem("user-token", data.accessToken);
      await StorageProvider.setItem("user-refresh-token", data.refreshToken);

      handleRedirect();
    } else {
      await clearStorages();

      notify.error(error?.data?.message || 'Authentication error');

      setLoading(false);
    }
  }

  const handleRedirect = async () => {
    setLoading(true);

    const { data: profile, error: profileError } = await getProfile(null, true);
    const { data: subscription, error: subscriptionError } = await getCurrentSubscription(null, true);

    if (profile && !profileError) {
      if (!profile.account.activated) {
        notify.error('Your accound is deactivated');

        clearStorages();
        history.push('/');
      } else if (!profile.user.jobRole) {
        history.push('/about-yourself');
      } else if (!subscription && !subscriptionError) {
        history.push('/choose-your-plan');
      } else {
        setLoading(true);

        history.push('/dashboard');
      }
    } else {
      await clearStorages();

      history.push('/');

      notify.error('Failed to get profile data. Try to login again or contact our support');
    }
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.layoutContainer}>
        <Box className={classes.emailContainer}>
          <Typography className={classes.sigupText}>
            Welcome back
          </Typography>
          <Typography className={classes.subTitle}>
            Don’t have an account?
            <span
              data-test-id="loginBtn"
              className={classes.loginText}
              onClick={handleSignUp}
            >
              {`Sign up`}
            </span>
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email.")
                .required("Email should not be blank."),
              password: Yup.string()
                .required("Password should not be blank."),
            })}
            onSubmit={(values, formik) => handleLogin(values.email, values.password)}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit
            }) => (
              <Form
                translate={undefined}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  marginTop: '32px'
                }}
              >
                <TextField
                  name={'email'}
                  value={values['email']}
                  placeholder={'Enter the email'}
                  label={'Email'}
                  error={touched['email'] && errors['email']}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                />
                <TextField
                  name={'password'}
                  value={values['password']}
                  placeholder={'Enter the password'}
                  label={'Password'}
                  type={'password'}
                  error={touched['password'] && errors['password']}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                />
                <Box className={classes.forgotPwdLabelWrapper}>
                  <Typography
                    className={classes.forgotPwdLink}
                    onClick={handleForgotPassword}
                  >
                    Forgot password?
                  </Typography>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{
                    marginTop: '16px'
                  }}
                >
                  Log in
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default Login;
