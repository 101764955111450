import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as RegenerateChatMessageIcon } from '../../../../assets/icons/regenerate_copilot_message.svg';
import { ReactComponent as CopyChatMessageIcon } from '../../../../assets/icons/copy_chat_message_icon.svg';
import { ReactComponent as LikeChatMessageIcon } from '../../../../assets/icons/like_copilot_message.svg';
import { ReactComponent as DislikeChatMessageIcon } from '../../../../assets/icons/dislike_copilot_message.svg';
import { likeMessage, dislikeMessage } from "../../../../redux/services/copilot";
import Markdown from "react-markdown";
import { marked } from "marked";
import { notify } from "../../../../providers/notification";
import { AnswerStatus, Chat, CopilotTypes, MessageAuthorTypes } from "../../../../interfaces/copilot";

import { useStyles } from "./styles";

interface Props {
  id: string;
  tab: CopilotTypes;
  chat: Chat;
  text: string;
  speed: number;
  author: MessageAuthorTypes;
  answerStatus: AnswerStatus;
  // allowRefetch={item.message === 'error' && index === chat.messages.length - 1}
  allowRefetch: boolean;
  allowAnimate: boolean;
  scrollToBottom: () => void;
  setChat: (chat: Chat) => void;
}

const TypingText: React.FC<Props> = ({ 
  id,
  tab,
  chat,
  text, 
  speed,
  author,
  answerStatus,
  allowRefetch,
  allowAnimate, 
  scrollToBottom,
  setChat,
}) => {
  const [displayedText, setDisplayedText] = useState<string>('');

  const classes = useStyles();
  
  useEffect(() => {
    if (author === 'user' || !allowAnimate || text === 'error') {
      setDisplayedText(text);
      scrollToBottom();
    } else {
      if (text.length) {
        setDisplayedText('');
        scrollToBottom();
  
        const characters = text.split('');
        let index = 0;
        
        const interval = setInterval(() => {
          setDisplayedText((prev) => prev + (characters[index] || '')); // Add a fallback for undefined characters
          index++;
  
          if (index >= characters.length) {
            clearInterval(interval);
          }
  
          if (index === Math.ceil(characters.length / 2) || index >= characters.length) {
            scrollToBottom();
          }
        }, speed);
  
        return () => clearInterval(interval);
      }
    }
  }, [text, speed, author]);

  const handleCopyMesssage = async () => {
    const htmlContent = await marked(text);
    const blob = new Blob([htmlContent], { type: "text/html" });
    const clipboardItem = new ClipboardItem({ "text/html": blob });

    navigator.clipboard.write([clipboardItem])
      .then(() => {
        notify.info('Copied to the clipboard');
      })
      .catch(() => {
        notify.error('Failed to copy');
      });
  }

  const handleLikeMessage = async () => {
    notify.success('Message liked');

    setChat({
      ...chat,
      messages: chat.messages.map(item => (
        item.id === id
          ? {
              ...item,
              answerStatus: AnswerStatus.LIKED
            }
          : item
      ))
    })

    await likeMessage({ 
      sessionId: chat.sessionId, 
      messageId: id, 
      tab: tab.toLowerCase()
    });
  }

  const handleDislikeMessage = async () => {
    setChat({
      ...chat,
      messages: chat.messages.map(item => (
        item.id === id
          ? {
              ...item,
              answerStatus: AnswerStatus.DISLIKED
            }
          : item
      ))
    })

    notify.success('Message disliked');

    await dislikeMessage({ 
      sessionId: chat.sessionId, 
      messageId: id, 
      tab: tab.toLowerCase()
    });
  }

  const handleRegenerateMessage = () => {
    
  }

  const handleReplaceWords = (displayedText) => {
    let result = displayedText;

    result = result.replace('Itseems', 'It seems');
    result = result.replace('Itlooks', 'It looks');
    result = result.replace('Itsounds', 'It sounds');

    return result;
  }

  return (
    <Box style={{ width: '100%' }}>
      <Typography className={classes.message}>
        {!!displayedText.length
          ? !!displayedText.replaceAll('error', '')?.length
            ? <Markdown
                className={classes.markdown}
              >
                {handleReplaceWords(displayedText)}
              </Markdown>
            : <Box className={classes.errorMeesage}>
                Hmm... Something seems to have gone wrong while generating the response.
              </Box>
          : <Box className={classes.progressBar} />
        }
      </Typography>
      {!!displayedText.length && (
        <Box className={classes.actionsBox}>
          {author === 'copilot' && allowRefetch && displayedText === 'error' && (
            <IconButton 
              size="small"
              onClick={() => handleRegenerateMessage()}
            >
              <Tooltip
                arrow
                enterDelay={1000}
                title={'Regenerate message'}
                placement="bottom"
              >
                <RegenerateChatMessageIcon />
              </Tooltip>
            </IconButton>
          )}
          
          {!!displayedText.replaceAll('error', '')?.length && (
            <IconButton 
              size="small"
              onClick={() => handleCopyMesssage()}
            >
              <Tooltip
                arrow
                enterDelay={1000}
                title={'Copy'}
                placement="bottom"
              >
                <CopyChatMessageIcon />
              </Tooltip>
            </IconButton>
          )}

          {author === 'copilot' && answerStatus && !!displayedText.replaceAll('error', '')?.length && (
            <>
              <Box className={classes.verticalSeparator} />
              {(answerStatus === 'LIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  // disabled={answerStatus === 'LIKED'}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleLikeMessage()
                    }
                  }}
                  style={{
                    opacity: answerStatus === 'LIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'LIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'LIKED'
                        ? 'You have liked this response'
                        : 'Good response'
                    }
                    placement="bottom"
                  >
                    <LikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
              {(answerStatus === 'DISLIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  // disabled={answerStatus === 'DISLIKED'}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleDislikeMessage()
                    }
                  }}
                  style={{
                    opacity: answerStatus === 'DISLIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'DISLIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'DISLIKED'
                        ? 'You have disliked this response'
                        : 'Bad response'
                    }
                    placement="bottom"
                  >
                    <DislikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TypingText;
