export const PERMISSIONS = {
  EDITOR: "Editor",
  REVIEWER: "Reviewer",
  ADMIN: "Admin"
  // SUPER_ADMIN: "Super Admin"
};

export const LAYOUTS = {
  CAROUSEL_LAYOUT: "CarouselLayout",
  ONBOARDING_LAYOUT: "OnboardingLayout",
  PLATFORM_LAYOUT: "PlatformLayout"
};

export const REVIEW_STATUS = {
  DRAFT: "DRAFT",
  PENDING_REVIEW: "PENDING_REVIEW",
  APPROVED: "APPROVED",
  CHANGE_REQUIRED: "CHANGE_REQUIRED",
  REJECTED: "REJECTED"
};
