import React, { useEffect, useState } from 'react';
import { HelmetProvider as Provider, Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { routeMap } from '../helpers/routes';
import { matchRoute } from '../helpers/routes';

const HelmetAsyncProvider = ({ children, location, history }) => {
  const [title, setTitle] = useState('Pebbles Ai');

  // useEffect(() => {
  //   const currentPath = location.pathname;
  //   const matchedRoute = Object.values(routeMap).find((route) => matchRoute(currentPath, route.path));

  //   if (matchedRoute.title) {
  //     setTitle(`${matchedRoute.title} | Pebbles Ai`);
  //   } else {
  //     setTitle('Pebbles Ai');
  //   }
  // }, [location.pathname, children]);
  
  return (
    <Provider>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      {children}
    </Provider>
  );
};

export const HelmetProvider = withRouter(HelmetAsyncProvider);
