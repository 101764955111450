import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  metadata: null
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.metadata = action.payload;  // Direct mutation
    },
    removeProfile: (state) => {
      state.metadata = null;  // Clear profile data
    },
  },
});

export const { 
  setProfile, 
  removeProfile 
} = profileSlice.actions;

export default profileSlice.reducer;