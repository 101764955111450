import React from "react";
import { ReduxProvider } from "./redux";
import { ThemeProvider } from "./theme";
import { PersistProvider } from "./persist";
import { LayoutProvider } from "./layout";
import { NotificationProvider } from "./notification";
import { AuthProvider } from "./auth";
import { HelmetProvider } from "./helmet";

export const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <NotificationProvider>
          <AuthProvider>
            <LayoutProvider>
              <HelmetProvider>
                <PersistProvider>
                  {children}
                </PersistProvider>
              </HelmetProvider>
            </LayoutProvider>
          </AuthProvider>
        </NotificationProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};
