import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@material-ui/core';
import TextField from '../../../../components/main/CustomFields/TextField';
import { isStartUpTeamsSubscription } from '../../../../helpers/roles';
import { LibraryTabs } from '../../../../interfaces/campaign';

import { useStyles } from './styles';

interface Props {
  searchTerm: string;
  subscription: any;
  currentTab: LibraryTabs;
  setCurrentTab: (tab: LibraryTabs) => void;
  setCampaigns: (newCampaigns: any) => void;
  handleSearchChange: (event: any) => void;
}

const Filters: React.FC<Props> = ({
  searchTerm,
  subscription,
  currentTab,
  setCurrentTab,
  setCampaigns,
  handleSearchChange,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.filters}>
      <Box className={classes.filtersLeftBlock}>
        {isStartUpTeamsSubscription(subscription) && (
          <Box className={classes.tabsBlock}>
            <Box 
              className={`${classes.tabsItem} ${currentTab === LibraryTabs.APPROVED ? classes.tabsItemActive : ''}`}
              onClick={() => {
                setCurrentTab(LibraryTabs.APPROVED);
                setCampaigns(null);
              }}
            >
              Approved
            </Box>
            <Box 
              className={`${classes.tabsItem} ${currentTab === LibraryTabs.IN_PROGRESS ? classes.tabsItemActive : ''}`}
              onClick={() => {
                setCurrentTab(LibraryTabs.IN_PROGRESS);
                setCampaigns(null);
              }}
            >
              In progress
            </Box>
          </Box>
        )}
      </Box>
      <Box 
        className={classes.filtersRightBlock}
        // style={{
        //   opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
        //   pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all',
        // }}
      >
        <TextField
          type={'search'}
          placeholder={'Search campaigns'}
          value={searchTerm}
          showClear
          onChange={handleSearchChange}
          onSearchPress={() => { }}
          onKeyPress={() => { }}
        />
      </Box>
    </Box>
  );
};

export default Filters;
