import moment from 'moment-timezone';

export const lastActivityDateFormat = (date) => {
  if (date) {
    const timezone = moment.tz.guess(); // Automatically detect the user's time zone
    const momentDate = moment.utc(date).tz(timezone);
    const today = moment.tz(timezone);
    const yesterday = moment.tz(timezone).subtract(1, 'days');

    if (momentDate.isSame(today, 'day')) {
      return `${momentDate.format('HH:mm')}, today`;
    } else if (momentDate.isSame(yesterday, 'day')) {
      return `${momentDate.format('HH:mm')}, yesterday`;
    } else {
      const daysAgo = today.diff(momentDate, 'days');

      return `${momentDate.format('HH:mm')}, ${daysAgo} days ago`;
    }
  } else {
    return 'never';
  }
};

export const isDateInPast = (date) => {
  const incomingDate = new Date(date);
  const now = new Date();

  // Compare the input date with the current date
  return incomingDate < now;
}