import { api, apiCall, createApiCall } from "../api";
import {
  CustomTopUp,
  TopUpPricingHolder,
  WalletBalanceResponse,
  WalletResponse
} from "../../interfaces/wallet";
import { ProductRequest, RevolutOrderResponse } from "../../interfaces/subscriptions";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query<WalletResponse[], string>({
      query: (uuid) => ({
        url: `/v1/license/wallet/account/${uuid}`,
        method: "GET"
      }),
    }),
    getWalletBalance: builder.query<WalletBalanceResponse, string>({
      query: (uuid) => ({
        url: `/v1/license/wallet/account/${uuid}/balance`,
        method: "GET"
      }),
    }),
    getTopUps: builder.query<TopUpPricingHolder, void>({
      query: () => ({
        url: `/v1/license/top-up/pricing`,
        method: "GET"
      }),
    }),
    createTopUpOrder: builder.query<RevolutOrderResponse, ProductRequest>({
      query: (body) => ({
        url: `/v1/license/orders`,
        method: "POST",
        body
      })
    }),
    createCustomTopUp: builder.query<CustomTopUp, { amount: number }>({
      query: (body) => ({
        url: `/v1/license/top-up/custom`,
        method: "POST",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getWallet = createApiCall<WalletResponse[], string>(subscriptionsAPI.endpoints.getWallet);
export const getWalletBalance = createApiCall<WalletBalanceResponse, string>(subscriptionsAPI.endpoints.getWalletBalance);
export const getTopUps = createApiCall<TopUpPricingHolder, void>(subscriptionsAPI.endpoints.getTopUps);
export const createTopUpOrder = createApiCall<RevolutOrderResponse, ProductRequest>(subscriptionsAPI.endpoints.createTopUpOrder);
export const createCustomTopUp = createApiCall<CustomTopUp, { amount: number }>(subscriptionsAPI.endpoints.createCustomTopUp);
