import React from 'react';
import { Modal, Box, IconButton, Typography, Button, Dialog, DialogContent } from '@material-ui/core';
import { Close } from "@material-ui/icons";

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose(): void;
  onYes(): void;
  onNo(): void;
}

const SkipOnboardingConfirmationDialog: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onYes,
    onNo,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { borderRadius: 20, maxWidth: 848, width: "100%" }
      }}
      style={{ zIndex: '999999' }}
    >
      <DialogContent className={classes.content}>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <Close />
        </IconButton>

        <Typography className={classes.description}>
          You have unsaved changes!
        </Typography>

        <Typography className={classes.subDescription}>
          Would you like to save?
        </Typography>

        <Box className={classes.action}>
          <Button
            fullWidth
            color={'secondary'}
            variant={'outlined'}
            onClick={onNo}
          >
            No
          </Button>
          <Button
            fullWidth
            color={'primary'}
            variant={'contained'}
            onClick={onYes}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SkipOnboardingConfirmationDialog;
