export const aboutCompanyOnboradingData = (data) => {
  const mustHaveSectionList = data.find((item: any) => item.detailsType === 'MUST' && (item. formObjectType === 'COMPANY' || item.formObjectType === 'COMPANY'))?.attributes;
  const shouldHaveSectionList = data.find((item: any) => item.detailsType === 'SHOULD' && (item. formObjectType === 'COMPANY' || item.formObjectType === 'COMPANY'))?.attributes;
  const goodToHaveSectionList = data.find((item: any) => item.detailsType === 'GOOD' && (item. formObjectType === 'COMPANY' || item.formObjectType === 'COMPANY'))?.attributes;

  const mustHaveSection = mustHaveSectionList
    ? {
        whyExist: mustHaveSectionList?.why_semantic_keywords,
        missionStatement: mustHaveSectionList?.mission_statement,
        unique_selling_proposition: mustHaveSectionList?.unique_selling_proposition,
        primaryFearQuestion1: mustHaveSectionList?.primary_fear_question[0],
        primaryFearQuestion2: mustHaveSectionList?.primary_fear_question[1],
        primaryFearQuestion3: mustHaveSectionList?.primary_fear_question[2],
        offering1: mustHaveSectionList?.problem_statement[0].title,
        description1: mustHaveSectionList?.problem_statement[0].description,
        offering2: mustHaveSectionList?.problem_statement[1].title,
        description2: mustHaveSectionList?.problem_statement[1].description,
        offering3: mustHaveSectionList?.problem_statement[2].title,
        description3: mustHaveSectionList?.problem_statement[2].description,
      }
    : {}

  const shouldHaveSection = shouldHaveSectionList 
    ? {
        companyEvalutionTime: [shouldHaveSectionList?.company_evolution_timeline],
        companyHeadquarters: [shouldHaveSectionList?.company_headquarters],
        companyAchivements: shouldHaveSectionList?.company_achievement,
        companyTestimonial: shouldHaveSectionList?.company_testimonial,
        keyCompanyFacts: shouldHaveSectionList?.key_company_fact
      } 
    : {}

  const goodHaveSection = goodToHaveSectionList 
    ? {
        visionStatement: [goodToHaveSectionList?.vision_statement],
        companyValue1: goodToHaveSectionList?.company_value[0].title || '',
        companyValue2: goodToHaveSectionList?.company_value[1].title || '',
        companyValue3: goodToHaveSectionList?.company_value[2].title || '',
        companyDescription1: goodToHaveSectionList?.company_value[0].description || '',
        companyDescription2: goodToHaveSectionList?.company_value[1].description || '',
        companyDescription3: goodToHaveSectionList?.company_value[2].description || '',
        primaryUtilityHook: [goodToHaveSectionList?.primary_utility_hook],
        mqlStatement: [goodToHaveSectionList?.mql_statement],
        contributeToSocity: [goodToHaveSectionList?.contribution_to_society],
      } 
    : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    // isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isMustHaveCompleted: !!(
      mustHaveSection?.whyExist?.length >= 10 &&
      mustHaveSection?.missionStatement?.length &&
      mustHaveSection?.unique_selling_proposition?.length &&
      mustHaveSection?.offering1?.length &&
      mustHaveSection?.description1?.length
    ),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export const yourApproachOnboradingData = (data) => {
  const mustHaveSectionList = data.find((item: any) => item.detailsType === 'MUST' && (item. formObjectType === 'APPROACH' || item.formObjectType === 'APPROACH'))?.attributes;
  const shouldHaveSectionList = data.find((item: any) => item.detailsType === 'SHOULD' && (item. formObjectType === 'APPROACH' || item.formObjectType === 'APPROACH'))?.attributes;
  const goodToHaveSectionList = data.find((item: any) => item.detailsType === 'GOOD' && (item. formObjectType === 'APPROACH' || item.formObjectType === 'APPROACH'))?.attributes;

  const mustHaveSection = mustHaveSectionList 
    ? {
        whyExist: mustHaveSectionList?.how_semantic_keywords,
        solutionTitle1: mustHaveSectionList?.solution_statement[0].title,
        solutionStatement1: mustHaveSectionList?.solution_statement[0].description,
        solutionTitle2: mustHaveSectionList?.solution_statement[1].title,
        solutionStatement2: mustHaveSectionList?.solution_statement[1].description,
        solutionTitle3: mustHaveSectionList?.solution_statement[2].title,
        solutionStatement3: mustHaveSectionList?.solution_statement[2].description,
        stage1: mustHaveSectionList?.high_level_approach[0].title,
        stageDescription1: mustHaveSectionList?.high_level_approach[0].description,
        stage2: mustHaveSectionList?.high_level_approach[1].title,
        stageDescription2: mustHaveSectionList?.high_level_approach[1].description,
        stage3: mustHaveSectionList?.high_level_approach[2].title,
        stageDescription3: mustHaveSectionList?.high_level_approach[2].description,
      } 
    : {}

  const shouldHaveSection = shouldHaveSectionList 
    ? {
        processStep: shouldHaveSectionList?.process.map((item: any) => item.title),
        processDescription: shouldHaveSectionList?.process.map((item: any) => item.description),
        processSection: shouldHaveSectionList?.process_testimonial,
        background: shouldHaveSectionList?.success_story[0]?.background,
        problem: shouldHaveSectionList?.success_story[0]?.problem,
        storySolution1: shouldHaveSectionList?.success_story[0]?.solutions[0],
        storySolution2: shouldHaveSectionList?.success_story[0]?.solutions[1],
        storySolution3: shouldHaveSectionList?.success_story[0]?.solutions[2],
        storyResult1: shouldHaveSectionList?.success_story[0]?.results[0],
        storyResult2: shouldHaveSectionList?.success_story[0]?.results[1],
        storyResult3: shouldHaveSectionList?.success_story[0]?.results[2],
      } 
    : {}

  const goodHaveSection = goodToHaveSectionList 
    ? {
        segmentOverview: [goodToHaveSectionList?.segment_expertise_overview],
        segmentTitle1: goodToHaveSectionList?.key_segment_differentiator[0].title,
        segmentTitle2: goodToHaveSectionList?.key_segment_differentiator[1].title,
        segmentTitle3: goodToHaveSectionList?.key_segment_differentiator[2].title,
        segmentDescription1: goodToHaveSectionList?.key_segment_differentiator[0].description,
        segmentDescription2: goodToHaveSectionList?.key_segment_differentiator[1].description,
        segmentDescription3: goodToHaveSectionList?.key_segment_differentiator[2].description,
        compareSection: [goodToHaveSectionList?.high_level_comparison],
      } 
    : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    // isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isMustHaveCompleted: !!(
      mustHaveSection?.whyExist?.length >= 10 && 
      mustHaveSection?.solutionStatement1?.length && 
      mustHaveSection?.solutionTitle1 && 
      mustHaveSection?.stageDescription1?.length && 
      mustHaveSection?.stage1
    ),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export const offeringsOnboradingData = (data) => {
  const mustHaveSectionData = data.find((item: any) => item.detailsType === 'MUST' && (item. formObjectType === 'OFFERINGS' || item.formObjectType === 'OFFERINGS'))?.attributes;
  const shouldHaveSectionData = data.find((item: any) => item.detailsType === 'SHOULD' && (item. formObjectType === 'OFFERINGS' || item.formObjectType === 'OFFERINGS'))?.attributes;
  const goodToHaveSectionData = data.find((item: any) => item.detailsType === 'GOOD' && (item. formObjectType === 'OFFERINGS' || item.formObjectType === 'OFFERINGS'))?.attributes;

  const mustHaveSection = mustHaveSectionData 
    ? {
        whyExist: mustHaveSectionData?.what_semantic_keywords,
        productAndFeature: mustHaveSectionData?.product_and_feature,
        offeringServices: mustHaveSectionData?.service,
      } 
    : {}

  const shouldHaveSection = shouldHaveSectionData
    ? {
        theTeam: shouldHaveSectionData?.team?.map(item => ({
          title: item.full_name + `${item.title ? ' | ' + item.title : ''}`,
          description: item.bio,
        })),
        productTestimonials: shouldHaveSectionData?.product_testimonial,
        performanceAnalystics: shouldHaveSectionData?.performance_analytics,
      } 
    : {}

  const goodHaveSection = goodToHaveSectionData
    ? {
        corporatePartners: [goodToHaveSectionData?.corporate_partnership],
        modularProducts: goodToHaveSectionData?.modular_products_and_services,
        packagesAndPricing: goodToHaveSectionData?.package_and_pricing.map(item => ({
          title: item.name,
          description: item.description,
          price: item.price,
          currency: item.currency,
        })),
      } 
    : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    // isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isMustHaveCompleted: !!(
      mustHaveSection?.whyExist?.length >= 10 &&
      mustHaveSection?.productAndFeature?.length > 1 &&
      mustHaveSection?.offeringServices?.length > 1
    ),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export function checkForCompleted(data) {
  if (!Object.keys(data).length) {
    return false;
  } else {
    const { detailsType, formObjectType, ...restData } = data;

    const checkValue = (value) => {
      if (Array.isArray(value)) {
          return value.every(checkValue);
      } else if (typeof value === 'object' && value !== null) {
          return checkForCompleted(value);
      } else {
        return value !== "" && value !== "null" && value !== null && value !== undefined;
      }
    };

    return Object.values(restData).every(checkValue);
  }
}

export function checkPartiallyFilled(data) {
  if (!Object.keys(data).length) {
    return false;
  } else {
    const checkValue = (value) => {
      if (Array.isArray(value)) {
        return value.some(checkValue);
      } else if (typeof value === 'object' && value !== null) {
        return checkPartiallyFilled(value);
      } else {
        return value !== "" && value !== "null" && value !== null && value !== undefined;
      }
    };

    return Object.values(data).some(checkValue);
  }
};