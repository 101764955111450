import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  commentItem: {
    width: '100%',
    // maxWidth: '218px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: '#F8FAFC',
    border: '1px solid #CBD5E1',
    borderRadius: '12px',
    padding: '16px 8px',
    position: 'relative',
    // cursor: 'pointer',
  },
  commentItemMenuIcon: {
    width: '36px',
    height: '36px',
    top: 5,
    right: 5,
    position: 'absolute',
  },
  commentItemMenuBackdrop: {
    '& .MuiPaper-root': {
      paddingTop: '5px',
      paddingBottom: '5px',
      borderRadius: '8px'
    }
  },
  commentItemActive: {
    boxShadow: '-2px 0 0 0 #57BE99 !important',
    cursor: 'auto'
  },
  commentItemResolved: {
    border: '1px solid #CBD5E1',
    background: '#CBD5E1 !important',
    opacity: '0.8'
  },
  commentItemActionLoader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    zIndex: 3,
    borderRadius: '12px',
    background: '#CBD5E14D',
    '& .MuiCircularProgress-svg': {
      color: '#6b6b6d',
    }
  },
  nestedItemsWrapper: {
    maxHeight: '0px',
    overflowX: 'hidden',
    overflowY: 'auto',
    animation: '$expand .3s ease-in-out forwards',
  },
  nestedItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    background: '#F8FAFC',
    padding: '10px 20px'
  },
  commentMessageInput: {
    position: 'relative',
    '& .MuiInputBase-root': {
      '& input': {
        paddingRight: '40px'
      }
    }
  },
  commentMessageSendIcon: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translate(-12px, -50%)',
    zoom: '0.8'
  },
  replyCounter: {
    display: 'flex', 
    alignItems: 'center', 
    gap: '8px',
    padding: '5px',
    borderRadius: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  },
  replyCounterText: {
    fontSize: '12px', 
    fontWeight: 500, 
    lineHeight: '15px', 
    color: '#3A84C9',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
  '@keyframes expand': {
    from: { 
      maxHeight: '0px',
      overflow: 'hidden',
    },
    to: { 
      maxHeight: '500px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
}));
