import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Link, Typography } from '@material-ui/core';
import { ReactComponent as ThreeReferralsIcon } from '../../assets/icons/3_referrals.svg';
import { ReactComponent as MassReferralIcon } from '../../assets/icons/mass_referal.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import ReferralsGiftRobotIcon from '../../assets/images/referrals_gift_robot.webp';
import WhatsAppIcon from '../../assets/icons/whats-app.svg';
import { getReferralCode, getReferralsAmount } from '../../redux/services/authentication';
import copy from 'copy-to-clipboard';
import { getProfile, subscribeForUpdates } from '../../redux/services/profile';
import * as clipboard from "clipboard-polyfill";
import { notify } from '../../providers/notification';

import { useStyles } from './styles';

const ShareAndEarn = () => {
  const [options, setOptions] = useState<any>([
    {
      icon: <ThreeReferralsIcon />,
      color: '#3A84C9',
      titleColor: '#3A84C9',
      title: 'Three for free',
      description: <>
        Invite <b>3 friends</b> to subscribe to GTM Professional and enjoy <b>1 month free</b>.
      </>,
      amount: {
        min: 0,
        required: 3,
        current: 0,
      },
    },
    {
      icon: <MassReferralIcon />,
      color: '#CAB354',
      titleColor: '#000000',
      title: 'Ten to win again',
      description: <>
        Invite <b>10 friends</b> to subscribe to GTM Professional and receive <b>6 months free</b>.
      </>,
      amount: {
        min: 3,
        required: 10,
        current: 0,
      },
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    handleGetReferralsAmount();
  }, []);

  const handleGetReferralsAmount = async () => {
    const { data, error } = await getReferralsAmount();

    if (data && !error) {
      setOptions(
        options.map(item => ({
          ...item,
          amount: {
            ...item.amount,
            current: data.amount >= item.amount.required ? item.amount.required : data.amount
          }
        }))
      )
    }
  }

  const handleCopyLink = async () => {
    try {
      const { data, error } = await getReferralCode();

      if (data && !error) {
        let link = `${window.location.origin}/signup?ref=${data.code}`;
        link = link.replace(/https?:\/\//g, "");

        // Clipboard write within a direct user interaction
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(link).then(() => {
            notify.info('Referral link copied to clipboard');
          }).catch(() => {
            clipboard.writeText("This text is plain.").then(
              () => {
                notify.info('Referral link copied to clipboard');
              },
              () => fallbackCopyTextToClipboard(link) // Fallback method for Safari
            );
          });
        } else {
          clipboard.writeText("This text is plain.").then(
            () => {
              notify.info('Referral link copied to clipboard');
            },
            () => fallbackCopyTextToClipboard(link) // Fallback method for Safari
          );
        }
      } else {
        notify.error('Server error occurred');
      }
    } catch (err) {
      notify.error('An unexpected error occurred');
    }

    // Fallback function for Safari and unsupported environments
    async function fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Make the textarea non-editable and off-screen
      textArea.style.position = "fixed";
      textArea.style.top = "-100px";
      textArea.style.left = "-100px";
      textArea.setAttribute("readonly", 'true');

      document.body.appendChild(textArea);
      textArea.select();

      try {
        const successful = document.execCommand("copy");

        if (successful) {
          notify.info('Referral link copied to clipboard');
        } else {
          copy(text);
        }
      } catch (err) {
        copy(text);
      } finally {
        document.body.removeChild(textArea);
      }
    }
  };

  const handleSubscribeForUpdates = async () => {
    const { data: profile } = await getProfile();

    if (profile.pebblesAcademySubscribed) {
      notify.info('You are already subscribed to the Pebbles.Ai Academy');
    } else {
      const { error } = await subscribeForUpdates({
        subscribe: true
      });

      if (!error) {
        getProfile(null, true);

        notify.success('You have just subscribed for updates');
      } else {
        notify.error('Failed to subscribe to the Pebbles.Ai Academy');
      }
    }
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <Typography className={classes.title}>
          Share & Earn
        </Typography>
        <Box className={classes.referralBoxContainer}>
          {options.map((item, index) => (
            <Box
              key={index}
              className={classes.referralBox}
              style={{
                borderColor: item.color
              }}
            >
              <Box className={classes.referralHeader}>
                {item.icon}
                <Typography className={classes.referralProgress}>
                  <Typography
                    className={classes.referralCount}
                    style={{
                      color: item.color
                    }}
                  >
                    {item.amount.current}&nbsp;
                  </Typography>
                  / {item.amount.required} referrals
                </Typography>
              </Box>
              <Box className={classes.referralDetails}>
                <Typography
                  className={classes.referralFriends}
                  style={{
                    color: item.titleColor
                  }}
                >
                  {item.title}
                </Typography>
                <Typography className={classes.referralText}>
                  {item.description}
                </Typography>
              </Box>
              <Button
                variant='outlined'
                color='secondary'
                startIcon={<LinkIcon style={{ color: item.color }} />}
                disabled={item.amount.current < item.amount.min || item.amount.current >= item.amount.required}
                onClick={() => handleCopyLink()}
              >
                Get a referral link
              </Button>
            </Box>
          ))}
        </Box>

        <Box className={classes.additionalOffersContainer}>
          <Box
            className={classes.inviteFriendsBox}
            onClick={() => handleSubscribeForUpdates()}
          >
            <Box className={classes.inviteFriendsContent}>
              <Typography className={classes.academyTitle}>
                Pebbles Ai Academy
              </Typography>
              <Typography className={classes.inviteText}>
                You’ve unlocked access to science-based sales training! <br />
                Click to subscribe for updates from Pebbles Ai Academy.
              </Typography>
            </Box>
            <img height={'200px'} src={ReferralsGiftRobotIcon} alt='Gift' />
          </Box>

          <Link
            className={classes.inviteFriendsBox}
            href={process.env.REACT_APP_COMMUNITY_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box className={classes.inviteFriendsContent}>
              <Typography className={classes.academyTitle}>
                WhatsApp Beta Community
              </Typography>
              <Typography className={classes.inviteText}>
                Click to join the community, connect with GTM professionals, request features, and report bugs
              </Typography>
            </Box>
            <Box style={{ padding: '10px' }}>
              <img src={WhatsAppIcon} alt='WhatsApp' />
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default ShareAndEarn;
