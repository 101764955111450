import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const Info = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.message}>
        {message}
      </Typography>
    </Box>
  );
}

export default Info;
