import { api, createApiCall } from "./../api";
import {
  Campaign, CampaignCommentRequest, CampaignCommentResponse, CampaignEmailRequest, CampaignEmailResponse,
  CampaignModelResponse, CampaignParameters,
  CampaignResponse,
  CampaignTypeResponse, EmailParameters, GetReplyTemplateRequest, ReplyTemplateModel, ReplyRequest, ReplyResponse
} from "../../interfaces/campaign";

const tacticalOutreachAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCampaigns: builder.query<CampaignTypeResponse, number>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaign_types?categoryId=${id}`,
        method: "GET"
      })
    }),
    getCampaignTemplate: builder.query<CampaignModelResponse, number>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaign_templates?typeId=${id}`,
        method: "GET"
      })
    }),
    saveCampaignTemplate: builder.mutation<CampaignResponse, { body: Campaign, saveAsModificator: string }>({
      query: ({ body, saveAsModificator }) => ({
        url: `/v1/tactical_outreach/campaigns${saveAsModificator}`,
        method: "POST",
        body
      })
    }),
    saveCampaignParameters: builder.mutation<CampaignParameters, { body: CampaignParameters, saveAsModificator: string }>({
      query: ({ body, saveAsModificator }) => ({
        url: `/v1/tactical_outreach/answers${saveAsModificator}`,
        method: "PUT",
        body
      })
    }),
    getCampaignParameters: builder.mutation<CampaignParameters, string>({
      query: (id) => ({
        url: `/v1/tactical_outreach/answers/${id}`,
        method: "GET"
      })
    }),
    triggerEmailGenerate: builder.mutation<CampaignEmailResponse, { type: string, campaignId: string }>({
      query: ({ type, campaignId }) => ({
        url: `/v1/tactical_outreach/emails/generate/${type}/${campaignId}`,
        method: "POST"
      })
    }),
    getCampaignEmails: builder.query<CampaignEmailResponse[], string>({
      query: (id) => ({
        url: `/v1/tactical_outreach/emails?campaignId=${id}`,
        method: "GET"
      })
    }),
    updateCampaignEmail: builder.mutation<CampaignEmailResponse, { body: CampaignEmailRequest, id: string }>({
      query: ({ body, id }) => ({
        url: `/v1/tactical_outreach/emails/${id}`,
        method: "PUT",
        body
      })
    }),
    getCampaignById: builder.query<CampaignResponse, string>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaigns/${id}`,
        method: "GET"
      })
    }),
    updateCampaign: builder.mutation<void, { body: Campaign, id: string }>({
      query: ({ body, id }) => ({
        url: `/v1/tactical_outreach/campaigns/${id}`,
        method: "PUT",
        body
      })
    }),
    regenerateEmail: builder.mutation<CampaignEmailResponse, { type: string, emailId: string }>({
      query: ({ type, emailId }) => ({
        url: `/v1/tactical_outreach/emails/regenerate/${type}/${emailId}`,
        method: "PUT"
      })
    }),
    getCampaignEmailParameters: builder.mutation<EmailParameters, string>({
      query: (id) => ({
        url: `/v1/tactical_outreach/email_parameters/${id}`,
        method: "GET"
      })
    }),
    saveCampaignEmailParameters: builder.mutation<EmailParameters, { body: EmailParameters, saveAsModificator: string }>({
      query: ({ body, saveAsModificator }) => ({
        url: `/v1/tactical_outreach/email_parameters${saveAsModificator}`,
        method: "PUT",
        body
      })
    }),
    getReplyTemplate: builder.mutation<ReplyTemplateModel, GetReplyTemplateRequest>({
      query: ({ body, emailId }) => ({
        url: `/v1/tactical-outreach/emails/${emailId}/reply/template`,
        method: "POST",
        body
      })
    }),
    generateReply: builder.mutation<ReplyResponse, { body: ReplyRequest, emailId: string | number }>({
      query: ({ body, emailId }) => ({
        url: `/v1/tactical-outreach/emails/${emailId}/reply`,
        method: "POST",
        body
      })
    }),
    approveCampaign: builder.mutation<void, string>({
      query: (campaignId) => ({
        url: `/v1/tactical_outreach/campaigns/${campaignId}/approve`,
        method: "PUT"
      })
    }),
    reviewCampaign: builder.mutation<void, string>({
      query: (campaignId) => ({
        url: `/v1/tactical_outreach/campaigns/${campaignId}/review`,
        method: "PUT"
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getAllCampaigns = createApiCall<CampaignTypeResponse, number>(tacticalOutreachAPI.endpoints.getAllCampaigns);
export const getCampaignTemplate = createApiCall<CampaignModelResponse, number>(tacticalOutreachAPI.endpoints.getCampaignTemplate);
export const saveCampaignTemplate = createApiCall<CampaignResponse, { body: Partial<Campaign>, saveAsModificator: string }>(tacticalOutreachAPI.endpoints.saveCampaignTemplate);
export const saveCampaignParameters = createApiCall<CampaignParameters, { body: CampaignParameters, saveAsModificator: string }>(tacticalOutreachAPI.endpoints.saveCampaignParameters);
export const getCampaignParameters = createApiCall<CampaignParameters, string>(tacticalOutreachAPI.endpoints.getCampaignParameters);
export const triggerEmailGenerate = createApiCall<CampaignEmailResponse, { type: string, campaignId: string }>(tacticalOutreachAPI.endpoints.triggerEmailGenerate);
export const getCampaignEmails = createApiCall<CampaignEmailResponse[], string>(tacticalOutreachAPI.endpoints.getCampaignEmails);
export const updateCampaignEmail = createApiCall<CampaignEmailResponse, { body: CampaignEmailRequest, id: string }>(tacticalOutreachAPI.endpoints.updateCampaignEmail);
export const getCampaignById = createApiCall<CampaignResponse, string>(tacticalOutreachAPI.endpoints.getCampaignById);
export const updateCampaign = createApiCall<void, { body: Partial<Campaign>, id: string | number }>(tacticalOutreachAPI.endpoints.updateCampaign);
export const regenerateEmail = createApiCall<CampaignEmailResponse, { type: string, emailId: string }>(tacticalOutreachAPI.endpoints.regenerateEmail);
export const getCampaignEmailParameters = createApiCall<EmailParameters, string>(tacticalOutreachAPI.endpoints.getCampaignEmailParameters);
export const saveCampaignEmailParameters = createApiCall<EmailParameters, { body: EmailParameters, saveAsModificator: string }>(tacticalOutreachAPI.endpoints.saveCampaignEmailParameters);
export const getReplyTemplate = createApiCall<ReplyTemplateModel, GetReplyTemplateRequest>(tacticalOutreachAPI.endpoints.getReplyTemplate);
export const generateReply = createApiCall<ReplyResponse, { body: ReplyRequest, emailId: string | number }>(tacticalOutreachAPI.endpoints.generateReply);
export const approveCampaign = createApiCall<void, string>(tacticalOutreachAPI.endpoints.approveCampaign);
export const reviewCampaign = createApiCall<void, string>(tacticalOutreachAPI.endpoints.reviewCampaign);
